<template>
  <div>
    <NotBuyModal v-if="isModal" @open-modal="isModal = false"/>
    <div class="fb-block">
      <div class="fb-block__wrapper">
        <div class="fb-block__rating">
          <div class="fb-block__rating-average">{{ rating }} / 5</div>
          <div class="fb-block__rating-stars">
            <StarsRatingVue
              :rating="rating"
              :starsLimit="5"
              :numbers="false"
            ></StarsRatingVue>
          </div>
          <div class="fb-block__rating-all">
            {{ allFeedback }} {{ $t("question") }}
          </div>
        </div>
        <div class="fb-block__lines">
          <div
            class="fb-block__line"
            v-for="(item,idx) in feedbacks_items"
            :key="item.id"
          >
            <div class="fb-block__line-star">
                {{ ++idx }}
            </div>
            <div class="fb-block__line-icon">
              <img src="../assets/icons/card/star_filled.svg" alt="" />
            </div>
            <div class="fb-block__line-rating">
              <div class="fb-block__line-rating-empty"></div>
              <div
                class="fb-block__line-rating-filled"
                :style="`width: ${item.persernts}%;`"
              ></div>
            </div>
            <div class="fb-block__line-number">
              {{ item.count }}
            </div>
          </div>
        </div>
      </div>
      <span v-if="getIsAuth">
        <button v-if="checkReview && getIsAuth" class="fb-block__button" @click="ordersTrue ? $emit('open-feedback-modal') : isModal = true">
          {{ $t("leaveReview") }}
        </button>
        <button v-else class="buttonUc">
          You have already left a review
        </button>
      </span>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NotBuyModal from "./NotBuyModal.vue";
import StarsRatingVue from "./StarsRating.vue";
export default {
  data() {
    return {
      rating: this.reviews.length ? (this.reviews.reduce((item, sum) => item + sum.rating, 0) / this.reviews.length).toFixed(1) : 0,
      isModal: false,
      feedbacks_items: [
        {
          id: 0,
          number: 5,
          count: this.reviews?.filter(item => item.rating === 1).length,
          persernts: this.reviews?.filter(item => item.rating === 1).length * 100 / this.reviews.length,
        },
        {
          id: 1,
          number: 4,
          count: this.reviews?.filter(item => item.rating === 2).length,
          persernts: this.reviews?.filter(item => item.rating === 2).length * 100 / this.reviews.length,
        },
        {
          id: 2,
          number: 3,
          count: this.reviews?.filter(item => item.rating === 3).length,
          persernts: this.reviews?.filter(item => item.rating === 3).length * 100 / this.reviews.length,
        },
        {
          id: 3,
          number: 2,
          count: this.reviews?.filter(item => item.rating === 4).length,
          persernts: this.reviews?.filter(item => item.rating === 4).length * 100 / this.reviews.length,
        },
        {
          id: 4,
          number: 1,
          count: this.reviews?.filter(item => item.rating === 5).length,
          persernts: this.reviews?.filter(item => item.rating === 5).length * 100 / this.reviews.length,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["ACCOUNT_ORDERS", "getOrderList", "getUser", "getIsAuth"]),
    raitingWidth(id) {
      return (this.feedbacks_items[id].count / this.all_feedback) * 100;
      // return 22
    },
    
    checkReview() {
      if (!this.getUser) {
        return false;
      }
      else {
        return !this.reviews.some(review => review.user.id === this.getUser.id)
      }
    },
    
    ordersTrue() {
      return this.getOrderList.length && this.getOrderList.some(subArr => subArr.product_id == this.ids);
    },

    // raitingWidthStyle(raitingWidth) {
    //     return `width: ${raitingWidth}%;`
    // }
    raitingWidthStyle(id) {
      return `width: ${this.raitingWidth(id)}%;`;
    },
  },
  components: {
    StarsRatingVue,
    NotBuyModal
},
  props: {
    ids: {
      type: String,
    },
    allFeedback: {
      type: Number,
      default: 1,
    },
    reviews: {
      type: Array,
      default: function () {
        return [];
      },
    },
    feedbacks: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  // props: [
  //     'rating', 'allFeedback', 'feedbacks'
  // ]
  watch: {
    review() {
      console.log(this.reviews);
      return this.reviews.length ? !this.reviews.some(review => review.user !== null ? review.user.id === this.getUser.id : false) : true;
    }
  }
};
</script>

<style lang="scss" scoped>
.buttonUc {
  cursor: pointer;
  margin-top: 32px;
  height: 52px;
  background: #05630E;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  color: white;
  padding: 0 22px;
  font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.3077))), 15px);
  transition: all 0.5s ease;
  &:hover {
    opacity: 0.85;
  }
}
.fb-block {
  padding-bottom: 30px;

  &__wrapper {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }

  &__rating {
    &-average {
      font-weight: 400;
      font-size: 44px;
      margin-bottom: 10px;
      @media (max-width: 576px) {
        font-size: 32px;
      }
    }

    &-all {
      margin-top: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #727272;
    }
  }

  &__line {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px;

    &-rating {
      position: relative;
      display: inline-flex;
      width: 230px;
      margin-bottom: 15px;
      @media (max-width: 576px) {
        width: 169px;
      }

      &-empty {
        position: absolute;
        width: 100%;
        height: 18px;
        left: 0px;
        top: 0px;
        background: #f7f7f7;
      }

      &-filled {
        position: absolute;
        height: 18px;
        left: 0px;
        top: 0px;
        background: #05630E;
      }
    }
  }

  &__button {
    margin-top: 20px;
    width: 222px;
    height: 52px;
    background: #05630E;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    color: #fff;
    transition: all 0.5s;

    &:hover {
      cursor: pointer;
      border: 1px solid #282828;
      background-color: #fff;
      color: #282828;
    }
  }
}
</style>