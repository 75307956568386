<template>
    <div class="singleCategory">
        <div class="content">
            <BreadCrumbs :items="[
                      [$t('catalog'), '/catalog'],
                      currentCategories.title !== 'Все категории'
                        ? [currentCategories.title, `/catalog/${currentCategories.slug}`]
                        : null,
                      currentTitle ? [currentTitle] : null,
                    ]" />
            <div class="singleCategory__title">
                <span v-if="currentTitle">{{ currentTitle }}</span>
                <span v-else>{{ currentCategories.title }}</span>
            </div>
            


            <div class="allCategory" v-if="this.$route.path == '/catalog'">
                <div class="allCategory__filter">
                    <div class=allCategory__filter__card v-for="item in categoryAll" :key="item">
                        <div class="allCategory__filter__card-img"><img :src="$cdn + item.image"/></div>
                        <p>{{ item.title }}</p>
                    </div>
                </div>
                <div class="allCategory__filter__category">
                    <div class="allCategory__filter__category__left">
                        <p class="allCategory__filter__category__left-p1">{{ $store.state.categories[0].title }}</p>
                        <div class="allCategory__filter__category__left-p2" v-for="item in $store.state.categories[0]?.sub_categories" :key="item">
                            <router-link :to="'/catalog/' + item.slug">{{ item.title }}</router-link>
                        </div>
                    </div>
                    <div class="allCategory__filter__category__right">
                        <div class="allCategory__filter__category__right__top">
                            <div class="allCategory__filter__category__right__top__box">
                                <p class="allCategory__filter__category__left-p1">{{ $store.state.categories[1].title }}</p>
                                <div class="allCategory__filter__category__left-p2" v-for="item in $store.state.categories[1]?.sub_categories" :key="item">
                                    <router-link :to="'/catalog/' + item.slug">{{ item.title }}</router-link>
                                </div>
                            </div>
                            <div class="allCategory__filter__category__right__top__box">
                                <p class="allCategory__filter__category__left-p1">{{ $store.state.categories[2].title }}</p>
                                <div class="allCategory__filter__category__left-p2" v-for="item in $store.state.categories[2]?.sub_categories" :key="item">
                                    <router-link :to="'/catalog/' + item.slug">{{ item.title }}</router-link>
                                </div>
                            </div>
                            <div class="allCategory__filter__category__right__top__box">
                                <p class="allCategory__filter__category__left-p1">{{ $store.state.categories[3].title }}</p>
                                <div class="allCategory__filter__category__left-p2" v-for="item in $store.state.categories[3]?.sub_categories" :key="item">
                                    <router-link :to="'/catalog/' + item.slug">{{ item.title }}</router-link>
                                </div>
                            </div>
                            <div class="allCategory__filter__category__right__top__box">
                                <p class="allCategory__filter__category__left-p1">{{ $store.state.categories[4].title }}</p>
                                <div class="allCategory__filter__category__left-p2" v-for="item in $store.state.categories[4]?.sub_categories" :key="item">
                                   <router-link :to="'/catalog/' + item.slug">{{ item.title }}</router-link>
                                </div>
                            </div>
                            <div class="allCategory__filter__category__right__top__box">
                                <p class="allCategory__filter__category__left-p1">{{ $store.state.categories[5].title }}</p>
                                <div class="allCategory__filter__category__left-p2" v-for="item in $store.state.categories[5]?.sub_categories" :key="item">
                                    <router-link :to="'/catalog/' + item.slug">{{ item.title }}</router-link>
                                </div>
                            </div>
                            <div class="allCategory__filter__category__right__top__box">
                                <p class="allCategory__filter__category__left-p1">{{ $store.state.categories[6].title }}</p>
                                <div class="allCategory__filter__category__left-p2" v-for="item in $store.state.categories[6]?.sub_categories" :key="item">
                                    <router-link :to="'/catalog/' + item.slug">{{ item.title }}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="singleCategory__wrapper" v-else>
                <div class="singleCategory__menu">
                    <!-- <div class="singleCategory__menu-title">{{ $t("category") }}</div> -->
                    <div class="singleCategory__tool">
                        <div class="singleCategory__tool-name acitveCategoryNone" @click="backAllCategories" :class="{acitveCategory: currentCategories.title !== 'Все категории'}">
                            <div class="singleCategory__tool-icon" v-if="currentCategories.title !== 'Все категории'" >
                                <img class="acitveCategoryNone-arrow" src="../assets/icons/home/arrow_left.png" alt="" />
                            </div>
                            <span>{{ currentCategories.title }}</span>
                        </div>
                        <div class="singleCategory__tool-items">
                            <template v-if="currentCategories.slug">
                              <router-link
                                :to="'/catalog/' + `${currentCategories.slug}/${item.slug}`"
                                v-for="(item, i) in showAllCategory
                                  ? currentCategories.sub_categories
                                  : currentCategories.sub_categories?.slice(
                                      selectCatId >= 10 ? selectCatId - 9 : 0,
                                      selectCatId >= 10 ? selectCatId + 1 : 10
                                    )"
                                @click="(currentCategoryId = item.id), (selectCatId = i)"
                                :key="i"
                                class="singleCategory__tool-item"
                                :class="{ active_category: item.slug == $route.params.id }"
                              >
                                {{ item.title }}
                              </router-link>
                            </template>

                            <template v-else>
                                <router-link :to="'/catalog/' + item.slug" v-for="(item, i) in showAllCategory
                                                            ? currentCategories.sub_categories
                                                            : currentCategories.sub_categories?.slice(selectCatId, 10)" @click="(currentCategoryId = item.id), (selectCatId = i)" :key="i" class="singleCategory__tool-item">
                                    {{ item.title }}
                                </router-link>
                            </template>
    </div>
    <div class="singleCategory__tool-item" @click="showAllCategory = !showAllCategory" :style="
                    currentCategories?.sub_categories?.length < 10
                      ? 'display: none'
                      : 'display: block'
                  " style="text-decoration: underline; font-weight: 500">
        {{ showAllCategory ? $t("hideAll") : $t("showAll") }}
    </div>
    </div>
    <div class="singleCategory__type">
        <div class="singleCategory__type-title">{{ $t("price") }}</div>
        
        <!-- <VueSimpleRangeSlider :min="0" :max="1000000" exponential v-model="range" @mouseup="mouseOver"/>
        <div class="singleCategory__cost-input" style="margin-top: 0">
            <input type="text" v-model="range[0]" disabled />
            <input type="text" v-model="range[1]" disabled />
        </div> -->
        <DoubleRangeSlider v-if="isDesktop" :min="0" :max="100000" :step="1" :range="range"
         @updatemin="updatemin" 
         @updatemax="updatemax"/>

        <div class="singleCategory__type-title">{{ $t("brand") }}</div>
        <div class="singleCategory__type-items">
            <div v-for="(item, i) in showAllBrands
                      ? filters.brands
                      : filters?.brands?.slice(0, 5)" :key="i" class="singleCategory__type-item">
                <input type="checkbox" :value="item.id" :id="item.id" v-model="checkedBrands" />
                <span>{{ item.title }}</span>
            </div>
        </div>
        <div v-if="filters?.brands?.length > 5" class="singleCategory__more" style="cursor: pointer" @click="showAllBrands = !showAllBrands">
            {{ !showAllBrands ? $t("showAll") : $t("hideAll") }}
        </div>
    </div>
    <div class="singleCategory__type" v-for="(item, i) in filters.filters" :key="i">
<template v-if="item.items.length>
     0">
    <div class="singleCategory__type-title">{{ item.title }}</div>
    <div class="singleCategory__type-items" :style="
                              item.showAll
                                ? 'height: auto'
                                : 'height: 118px; overflow: hidden'
                            ">
        <div v-for="(elem, i) in item.items" :key="i" class="singleCategory__type-item">
            <input type="checkbox" :value="elem.id" :id="elem.id" v-model="checkedFilters" />
            <span>{{ elem.title }}</span>
        </div>
    </div>
    <div class="singleCategory__more" style="cursor: pointer" @click="item.showAll = !item.showAll" v-if="item?.items?.length > 3">
        {{ item.showAll ? $t("hideAll") : $t("showAll") }}
    </div>
</template>
          </div>
        </div>
        <div class="singleCategory__main">
          <div class="singleCategory__main-input">
            <div
              class="singleCategory__main-input-filter"
              @click="filter = true"
            >
              <div class="singleCategory__main-input-icon">
                <img src="../assets/icons/mobile/filters.svg" alt="" />
              </div>
              Фильтр
            </div>
            <SelectComponentFilter  :options="[{
              value: 'asc',
              label: $t('ascendingOrder')
            },{
              value: 'desc',
              label: $t('descendingOrder')
            }]"
              @selected="value => sortt = value.value"
              name="select-times"
              placeholder="Choose time"
              :defaultOption="{
                value: 'asc',
                label: $t('ascendingOrder')
              }"  class="selectComFilterSize"/>

            <!-- <select v-model="sortt" class="custom-select">
                <option value="asc">
                  {{ $t("ascendingOrder") }}
                </option>
                <option value="desc">
                  {{ $t("descendingOrder") }}
                </option>
            </select> -->

            <!-- <div class="selectCustomSize">
              <select v-model="sortt">
                <option value="asc">
                  {{ $t("ascendingOrder") }}
                </option>
                <option value="desc">
                  {{ $t("descendingOrder") }}
                </option>
              </select>
              <img src="@/assets/icons/arrow.svg"/>
            </div> -->


            <!-- mobile filter modal -->
            <div class="singleCategory__filter" :style="filter ? 'top: 55px !important; transition: top 1s;' : 'top: 100% !important; transition: top 1s;'">
              <div class="singleCategory__filter-content">
                <div class="singleCategory__filter-header">
                  <div class="singleCategory__filter-header-title">Фильтр</div>
                  <div
                    class="singleCategory__filter-header-close"
                    @click="filter = false"
                  >
                    <img src="../assets/icons/components_icon/close.svg" alt="" />
                  </div>
                </div>
                <div class="singleCategory__type">
                  <div class="singleCategory__type-title">{{ $t("price") }}</div>

                  <!-- <VueSimpleRangeSlider
                    :min="0"
                    :max="1000000"
                    exponential
                    v-model="range"/> -->
                    <DoubleRangeSlider v-if="!isDesktop" :min="0" :max="100000" :step="1" :range="range"
                      @updatemin="updatemin" 
                      @updatemax="updatemax"/>

                  <!-- <div class="singleCategory__cost-input" style="margin-top: 0">
                    <input type="text" v-model="range[0]" disabled />
                    <input type="text" v-model="range[1]" disabled />
                  </div> -->
                  
                  <div class="singleCategory__type-title titleFlex" @click="toast($event.target)">
                    {{ $t("brand") }}
                    <img :src="require('@/assets/icons/rightCatalog.png')" alt="right"/>
                  </div>
                  <div class="singleCategory__type-items" style="display: none;">
                    <div
                      v-for="(item, i) in showAllBrands
                        ? filters.brands
                        : filters?.brands?.slice(0, 5)"
                      :key="i"
                      class="singleCategory__type-item"
                    >
                      <input
                        type="checkbox"
                        :value="item.id"
                        :id="item.id"
                        v-model="checkedBrands"
                      />
                      <span>{{ item.title }}</span>
                    </div>
                  <div
                    v-if="filters?.brands?.length > 5"
                    class="singleCategory__more"
                    style="cursor: pointer"
                    @click="showAllBrands = !showAllBrands"
                  >
                    {{ !showAllBrands ? $t("showAll") : $t("hideAll") }}
                  </div>
                  </div>
                </div>
                <div
                  class="singleCategory__type"
                  v-for="(item, i) in filters.filters"
                  :key="i"
                >
                  <template v-if="item.items.length>
                      0">
                      <div class="singleCategory__type-title titleFlex" @click.stop="toast($event.target)">
                        {{ item.title }}
                        <img :src="require('@/assets/icons/rightCatalog.png')" alt="right"/>
                      </div>
                      <div class="singleCategory__type-items"  style="display: none;" >
                          <div v-for="(elem, i) in item.items" :key="i" class="singleCategory__type-item">
                              <input type="checkbox" :value="elem.id" :id="elem.id" v-model="checkedFilters" />
                              <span>{{ elem.title }}</span>
                          </div>
                      </div>
                  </template>
                </div>
              </div>
              <div class="singleCategory__filter-button" @click="filter=false">{{ $t('apply') }}</div>
            </div>
            <!-- mobile filter modal end! -->
          </div>
          <div class="singleCategory__main-items">
            <div
              class="singleCategory__main-item"
              v-for="item in products"
              :key="item.id"
            >
              <Card :product="item"></Card>
            </div>
          </div>
          <!-- <div
            class="singleCategory__main-items"
            style="margin-top: 20px; justify-content: center"
          >
            Нет данных
          </div> -->
          <!-- <div
            class="singleCategory__main-pagination"
            v-if="products.length > 12"
          >
            <div class="singleCategory__main-count">
              <span class="active_page">1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
            </div>
            <div class="singleCategory__main-icon">
              <img src="../assets/icons/components_icon/next_page.svg" alt="" />
            </div>
          </div> -->
          <div
            style="display: flex; justify-content: center"
            v-if="datas.last_page > 1"
          >
            <vue-awesome-paginate
              :total-items="datas.last_page * 5"
              :items-per-page="5"
              :max-pages-shown="5"
              v-model="currentPage"
              @click="onClickHandler"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Card from "../components/GoodsCard.vue";
import VueSimpleRangeSlider from "vue-simple-range-slider";
import "vue-simple-range-slider/css";
import "vue-awesome-paginate/dist/style.css";
import { reactive } from "vue";
import BreadCrumbs from "@/components/UI/BreadCrumbs.vue";
import DoubleRange from "@/components/UI/DoubleRange.vue";
import SelectComponentFilter from "@/components/UI/SelectComponentFilter.vue";
import DoubleRangeSlider from "@/components/UI/DoubleRangeSlider.vue";
export default {
    data() {
    return {
          categoryAll: [],
          range: [
            2000, 80000
          ],
      isCheckboxChecked: false,
          isDesktop: window.screen.width > 992,
          priceArea: {
              minPrice: 0,
              maxPrice: 0
            },
            currentPage: parseInt(this.$route.query.page) || 1,
            filter: false,
            minPrice: 10,
            maxPrice: 1000,
            min_cost: "",
            max_cost: "",
            currentId: null,
            sortt: 'asc',
            currentCategories: {},
            currentTitle: null,
            selectCatId: null,
            showAllCategory: false,
            showAllBrands: false,
            filters: [],
            products: [],
            datas: [],
            currentCategoryId: null,
            checkedBrands: [],
            checkedFilters: [],
            cards: [{
                    id: 0,
                    image: require("../assets/img/good2.png"),
                    sale_persents: "30",
                    discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
                    price: 19390,
                    old_price: 33490,
                    count: 12,
                    link: "product",
                    fix_count: 12,
                },
                {
                    id: 1,
                    image: require("../assets/img/good2.png"),
                    sale_persents: "30",
                    discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
                    price: 19390,
                    old_price: 33490,
                    count: 12,
                    link: "product",
                    fix_count: 12,
                },
                {
                    id: 2,
                    image: require("../assets/img/good2.png"),
                    sale_persents: "30",
                    discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
                    price: 19390,
                    old_price: 33490,
                    count: 12,
                    link: "product",
                    fix_count: 12,
                },
                {
                    id: 3,
                    image: require("../assets/img/good2.png"),
                    sale_persents: "30",
                    discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
                    price: 19390,
                    old_price: 33490,
                    count: 12,
                    link: "product",
                    fix_count: 12,
                },
                {
                    id: 4,
                    image: require("../assets/img/good2.png"),
                    sale_persents: "30",
                    discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
                    price: 19390,
                    old_price: 33490,
                    count: 12,
                    link: "product",
                    fix_count: 12,
                },
                {
                    id: 5,
                    image: require("../assets/img/good2.png"),
                    sale_persents: "30",
                    discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
                    price: 19390,
                    old_price: 33490,
                    count: 12,
                    link: "product",
                    fix_count: 12,
                },
                {
                    id: 6,
                    image: require("../assets/img/good2.png"),
                    sale_persents: "30",
                    discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
                    price: 19390,
                    old_price: 33490,
                    count: 12,
                    link: "product",
                    fix_count: 12,
                },
                {
                    id: 7,
                    image: require("../assets/img/good2.png"),
                    sale_persents: "30",
                    discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
                    price: 19390,
                    old_price: 33490,
                    count: 12,
                    link: "product",
                    fix_count: 12,
                },
            ], // get with api
        };
    },
    // setup() {
    //   const state = reactive({ range: [20, 1000] });
    //   return { state };
    // },
    async created() {
        window.scrollTo(0, 0);
        await this.setProductList();
        this.$route.name !== "catalog" ? await this.allCateg() : await this.backAllCategories();


        await this.$axios
        .get(`V1/home-page`)
        .then((response) => {
          this.categoryAll = response.data.data.categories;
        })
        .catch((e) => {
          // console.log(e);
        });
    },
    methods: {
        ...mapActions(["GET_PRODUCT_LIST"]),        
        toast(event) {
          event.classList.toggle("activeTitle");
          var panel = event.nextElementSibling;
          if (panel.style.display === "block" || panel.style.display === null) {
            panel.style.display = "none";
          } else {
            panel.style.display = "block";
          }
        },
        async setProductList() {
            await this.GET_PRODUCT_LIST();
            this.cards = this.STATE.product;
      },
      updatemin(value) {
         this.range[0] = value 
          this.getProducts(this.currentId, true);
      }, 
      updatemax(value) {
        this.range[1] = value 
        
        this.getProducts(this.currentId, true);
      },
        async getFilters(currentId) {
            let data = new FormData();
            currentId ? data.append("category_id[]", currentId) : null;
            await this.$axios
                .post(`V1/filters`, data)
                .then((response) => {
                    this.filters = response.data;
                })
                .catch((e) => {
                    // console.log(e);
                });
            this.filters.filters.map((item, i) => {
                item.items.length > 3 ?
                    (this.filters.filters[i].showAll = false) :
                    (this.filters.filters[i].showAll = true);
            });
        },
        async backAllCategories() {
            var newArray = [];
            await this.allCategories.map((item) => {
                    newArray.push({
                        id: item.id,
                        slug: item.slug,
                        title: item.title,
                    });
                }),
                (this.currentCategories = {
                    title: "Все категории",
                    sub_categories: newArray,
                });
            this.$router.push("/catalog");
            this.currentTitle = "";
            this.currentId = false;
            this.getProducts(this.currentId);
            this.getFilters(this.currentId);
        },
        async allCateg() {
            await this.allCategories.map((item) => {
                item.slug == this.$route.fullPath.split("/")[2].split("?")[0] ?
                    ((this.currentCategories = item), (this.currentId = item.id)) :
                    null;
                item.sub_categories.map((elem) => {
                    elem.slug == this.$route.params.id ?
                        ((this.currentId = elem.id), (this.currentTitle = elem.title)) :
                        null;
                });
            });
        },
        async getProducts(category_id, bool) {
            let data = new FormData();
            this.checkedBrands.map((item) => {
                data.append("brand_id[]", item);
            });
            this.checkedFilters.map((item) => {
                data.append("filter_id[]", item);
            });
            if (bool) {
                data.append("price_from", this.range[0]);
                data.append("price_to", this.range[1]);
            }
            data.append("sort", `${this.sortt}`);
            category_id ? data.append("category_id[]", category_id) : null;
            await this.$axios
                .post(`V1/products?page=${this.currentPage}`, data)
                .then((res) => {
                  this.products = res.data.data.data;
                  // console.log("Запрос фильтра");
                    // res.data.data.data.length ?
                    // res.data.data.data.forEach(async item => {
                    //   await this.products.push(item)
                    // }) : ''
                    // console.log(this.products);
                    this.datas = res.data.data;
                })
              .catch(() => {
                  // console.log("Запрос фильтра");
                });
        },
        onClickHandler() {
            this.$router.push(`?page=${this.currentPage}`)
            this.getProducts(this.currentId);
        },
    },
  watch: {
        sortt() {
            this.getProducts(this.currentId);          
        },
        filter() {
            if (this.filter === true) {
                document.body.style.overflowY = 'hidden'
                document.documentElement.style.overflowY = 'hidden'
            } else {
                document.body.style.overflowY = 'auto'
                document.documentElement.style.overflowY = 'auto'
            }
        },
        $route() {
            this.$route.name !== "catalog" ?
                this.allCateg() :
                this.backAllCategories();
            (this.checkedBrands = []), (this.checkedFilters = []);
            this.showAllCategory = true;
            this.filter = false
          this.currentPage = parseInt(this.$route.query.page) || 1
        },
        allCategories() {
            this.$route.name !== "catalog" ?
                this.allCateg() :
                this.backAllCategories();
        },
        currentId() {
            this.getProducts(this.currentId);
            this.getFilters(this.currentId);
        },
        checkedBrands() {
            this.getProducts(this.currentId);
        },
        checkedFilters() {
            this.getProducts(this.currentId);
        },
        currentCategories() {
            this.currentCategories?.sub_categories?.length < 10 ?
                (this.showAllCategory = true) :
                (this.showAllCategory = false);
        },
    },
    computed: {
        ...mapGetters(["STATE", "allCategories"]),
    },
    components: {
    Card,
    VueSimpleRangeSlider,
    BreadCrumbs,
    DoubleRange,
    SelectComponentFilter,
    DoubleRangeSlider
},
};
</script>

<style lang="scss" scoped> 
.allCategory {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  margin-bottom: 82px;
  &__filter {
    display: flex;
    justify-content: space-between;
    &__card {
      width: 11.74%;
      &-img {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 10px;
        overflow: hidden;
        width: 100%;
        height: 130px;
        img {
          width: 100%;
          height: 100%;
          object-fit: none;
        }
      }
      p {
        margin-top: 20px;
        font-weight: 600;
        font-size: 16px;
        text-align: center;
      }
    }
    &__category {
      margin-top: 82px;
      width: 81.6%;
      margin-right: auto;
      margin-left: auto;
      display: flex;
      justify-content: space-between;
      &__left {
        width: 16.69%;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        &-p1 {
          a {
            color: black;
          }
          font-weight: 600;
        }
        &-p2 {
          a {
            color: black;
          }
          font-weight: 400;
        }
      }
      &__right {
        width: 73.46%;
        display: flex;
        flex-direction: column;
        gap: 60px;
        &__top {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          row-gap: 60px;
          &__box {
            width: 25.18%;
            display: flex;
            flex-direction: column;
            gap: 25px;
          }
        }
      }
    }
  }
}
.selectComFilterSize {
  width: 22.56%;
  @media (max-width: 991px) {
    width: 50%;
  }
}
.selectCustomSize {
  background: #FFFFFF;
  border: 1px solid #E3E5E6;
  width: 22.5%;
  padding: 0 12px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  select {
    font-size: 16px;
    border: none;
    padding: 0;
    margin: 0;
    background: none;
    appearance: none;
    outline: none;
    option {
      height: 50px !important;
      width: 10px !important;
    }
  }
  img {
    width: 12px;
  }
}
.titleFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  width: 100%;
  img {
    width: 8.11px;
    height: 16px;
    transform: rotate(0deg); 
    transition: transform 0.5s;
  }
}
.activeTitle {
  img {
    transform: rotate(-90deg); 
    transition: transform 0.5s;
  }
}
.singleCategory__filter-content {
  height: 88%;
  overflow-y: auto;
  padding-right: 10px;
  overflow-x: hidden;
  @media screen and (max-height: 667px) {
    height: 85%;    
  }
  &::-webkit-scrollbar {
    width: 2px;
  }
}
.singleCategory__filter-button {
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: 129px;
  padding: 16px;
  color: white;
  text-align: center;
  background: #202020;
  &:hover {
    cursor: pointer;
  }
}

.custom-select {
  position: relative;
  display: inline-block;
  width: 237px;
  height: 48px;
  padding: 8px 12px;
  color: #333;
  background-color: #fff;
  border: 1px solid #E3E5E6;
  border-radius: 4px;
  font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1538))), 14px);
  @media screen and (max-width: 992px) {
    width: 48%;
  }
  option {
    font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1538))), 14px);
    padding: 16px;
  }
  &:focus {
    outline: none;
    border-color: #E3E5E6;
  }
  &:after {
    content: 'dasd';
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 12px;
    width: 20px;
    height: 20px;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: #333 transparent transparent transparent;
    transform: translateY(-50%);
    pointer-events: none;
  }
}
.acitveCategory {
  background: #efeded;
  padding: 8px 13px !important;
  
}
.acitveCategoryNone {
  background: #efeded;
  padding: 8px 25px;
  &-arrow {
    position: relative;
    top: 1px;
    left: -7px;
  }
}

</style>