<template>
  <div>
    <div class="bill">
      <div class="bill__title">Ready to order:</div>
      <div class="bill__items">
        <div class="bill__item" v-for="(item, index) in items" :key="index">
          <label class="checkbox-google">
            <input type="checkbox" @change="checked(item)" :checked="item.checked" />
            <span class="checkbox-google-switch"></span>
          </label>
          <div class="bill__content">
            <div class="bill__summary">
              <div class="bill__number">Order № {{ ++index }}</div>
              <div class="bill__way" v-if="isWay == 'courier'">{{ this.$t("delivery") }}</div>
            </div>
            <div class="bill__delivery">
              <div class="bill__cost">{{ item.current_price * item.quantity }} тг</div>
              <div class="bill__cost" v-if="isWay == 'courier'">
                {{ product.delivery }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bill__promo">
        <input type="text" placeholder="Введите промокод" v-model="promo" />
        <button class="bill__promo-button" @click="getPromo2(promo)">></button>
      </div>
      <div class="bill__total">
        <span> {{ $t("total") }} </span>
        <span>
          {{ product.summary }}
        </span>
      </div>
      <div class="bill__goods">
        <span> Products, {{ product.goods }} {{ $t("pc") }} </span>
        <div class="bill__goods-cost">{{ product.cost }} £</div>
      </div>
      <div class="bill__sale" v-if="product.sale > 0">
        <span> {{ $t("discount") }} </span>
        <div class="bill__sale-red">- {{ product.sale }} £</div>
      </div>
      <div class="bill__sale" v-if="promoPercent !== 0"> 
        <span> Promocode </span>
        <div class="bill__sale-red">{{ promoPercent }} %</div>
      </div>
      <div class="bill__sale">
        <span> Общая сумма доставки </span>
        <div class="bill__sale-cost" v-if="isWay == 'courier'">{{ product.totalDelivery }} £</div>

        
        <div class="bill__sale-cost" v-else>0 £</div>
      </div>
      <!-- <div class="bill__check">Выставить КП</div> -->
      <button class="bill__button" @click="getOrder">
        {{ $t("sendApp") }}
      </button>
    </div>
  </div>
</template>


<script>
import { mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      totalPercent: 0,
      promoPercent: 0,
      promo: "",
      sale_presents: "",
      isAddToOrder: [],
      product: {
        summary: 0,
        goods: 0,
        cost: 0,
        sale: 0,
        delivery: 1000,
        totalDelivery: 0
      },
    };
  },
  watch: {
    isAddToOrder(newValue, oldValue) {
      // console.log("newValue: ", newValue, "oldValue: ", oldValue);
    },
    isWay() {
      this.setProduct()
    }
  },
  async created() {
    this.setProduct();
    // console.log(this.isAddToOrder);
  },
  methods: {
    ...mapActions(["GET_CHECKED_PROMO_CODE", "getOrders"]),
    ...mapMutations(["DELETE_All_BASKET"]),
    checked(item) {
      item.checked = !item.checked;
      this.setProduct();
    },
    async getPromo2(promo) {
      await this.$axios.get('V1/promocode', {
        params: {
            code: promo,
            price: this.product.summary
        },
        headers: {
            Authorization: "Bearer ".concat(
                localStorage.getItem("access_token")
            ),
        },
      })
        .then(res => {
          this.promoPercent = res.data.percent;
          this.setProduct();
        })
        .catch(err => {
        })
    },
    async getPromo(promo) {
      if (promo) {
        const checkedPromo = this.GET_CHECKED_PROMO_CODE(promo);
        // console.log("checkedPromo:", checkedPromo);
      } else {
        alert("123");
      }
    },
    async getOrder() {
      const date = new Date();
      const year = date.getFullYear().toString().padStart(4, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      let data = new FormData();
      data.append("name", this.getUser.name);
      data.append("surname", this.getUser.sname);
      data.append("email", this.getUser.email);
      data.append("phone", this.getUser.phone_number);
      data.append("delivery_type", this.isWay === 'courier' ? 'delivery' : 'self');
      data.append("delivery_date", formattedDate);
      data.append("payment_type", 'cash');
      data.append("total_price", this.product.summary);
      data.append("comment", this.comment);
      if (this.promoPercent > 0) { 
        data.append("promocode", this.promoPercent);
      }
      this.items.forEach((item, id) => {
        if (item.checked === true) {
          data.append(`products[${id}][id]`, item.id);
          data.append(`products[${id}][count]`, item.quantity);
          data.append(`products[${id}][price]`, item.current_price);
        }
      })
      await this.$axios.post("/V1/add-order", data,  {
        headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
      }).then(res => {
        this.getOrders()
        this.DELETE_All_BASKET()
        this.$router.push("/end-order");
      }).catch(err => {
        this.$toaster.error('Произошло ошибка')
      })
    },
    // recountCost(sale) {
    //   // let sale_presents = sale / 100
    //   this.product.summary = (this.product.summary * sale) / 100;
    //   this.product.summary.toFixed(1);

    //   this.setProduct();
    // },
    async setProduct() {
      this.product.totalDelivery = 0;
      this.product.summary = 0;
      this.product.goods = 0;
      this.product.cost = 0;
      this.product.sale = 0;

      // console.log('start');

      for (let i = 0; i < this.items?.length; i++) {
        if (!this.items[i].checked) {
          continue;
        }

        let singeProductCost = this.items[i].quantity * this.items[i].current_price;
        // let singeProductCostWithoutSale =
        //   this.items[i].quantity * this.items[i].current_price;
        this.product.goods += this.items[i].quantity;
        this.product.cost += singeProductCost;

        // вычисляем доставку для каждого товара и добавляем к totalDelivery
        let deliveryForProduct = this.product.delivery;
        this.product.totalDelivery += deliveryForProduct;

        this.product.sale += ((this.items[i].quantity * this.items[i].current_price)/100)*this.items[i].sale;
      }
      this.product.summary = this.product.cost - this.product.sale;
      // добавляем totalDelivery к итоговой сумме
      this.isWay === 'courier' ? this.product.summary += this.product.totalDelivery : this.product.summary
      if (this.promoPercent > 0) { 
        this.product.summary = (this.product.summary - ((this.product.summary * this.promoPercent) / 100)).toFixed(1);;
      }
      // this.product.summary += this.product.totalDelivery;
    }
  },
  props: ["items", 'isWay', 'getUser', 'comment'],
};
</script>



<style lang="scss" scoped>
.bill__sale-red {
  color: #ED3737;
  font-weight: 500;
}
.bill {
  padding: 24px 20px;
  width: 431px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 1600px) {
    width: 381px;
  }

  @media (max-width: 991px) {
    width: 100%;
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    color: #202020;
  }

  &__item {
    margin-top: 24px;
    display: flex;
    gap: 0 10px;
    align-items: flex-start;
  }

  &__content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__summary {
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 12px 0;
    font-size: 16px;
    line-height: 20px;
    color: #202020;
  }

  &__delivery {
    font-weight: 400;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 12px 0;
    font-size: 16px;
    line-height: 20px;
    color: #202020;
  }

  &__promo {
    margin-top: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      padding: 16px 24px 16px 16px;
      width: 339px;
      height: 52px;
      border: 1px solid #c2c2c2;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 52px;
      height: 52px;
      background: #05630E;
      color: #ffffff;
      font-size: 26px;
    }
  }

  &__total {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 26px;
    color: #202020;
  }

  &__goods {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-cost {
      font-weight: 500;
      font-size: 18px;
      color: #202020;
    }

    span {
      font-weight: 400;
      font-size: 18px;
      color: #727272;
    }
  }

  &__sale {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-cost {
      font-weight: 500;
      font-size: 18px;
      color: #202020;
    }

    span {
      font-weight: 400;
      font-size: 18px;
      color: #727272;
    }
  }

  &__check {
    margin-top: 24px;
    font-weight: 400;
    font-size: 18px;
    text-decoration-line: underline;
    color: #202020;
  }

  &__button {
    margin-top: 24px;
    padding: 16px 24px;
    width: 100%;
    height: 51px;
    border-radius: 10px;
    background: #05630E;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
  }
}

.checkbox-google {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  margin-right: 10px;
  position: relative;
  vertical-align: middle;
  font-size: 14px;
  user-select: none;
}

.checkbox-google .checkbox-google-switch {
  display: inline-block;
  width: 36px;
  height: 14px;
  border-radius: 20px;
  position: relative;
  top: 6px;
  vertical-align: top;
  background: #9f9f9f;
  transition: 0.2s;
}

.checkbox-google .checkbox-google-switch:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -3px;
  left: -1px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: 0.15s;
}

.checkbox-google input[type="checkbox"] {
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox-google input[type="checkbox"]:checked + .checkbox-google-switch {
  background: #05630E;
}

.checkbox-google
  input[type="checkbox"]:checked
  + .checkbox-google-switch:before {
  background: #e3e5e6;
  transform: translateX(18px);
}

/* Hover */
.checkbox-google
  input[type="checkbox"]:not(:disabled)
  + .checkbox-google-switch {
  cursor: pointer;
  border-color: rgba(0, 0, 0, 0.3);
}

/* Active/Focus */
.checkbox-google
  input[type="checkbox"]:not(:disabled):active
  + .checkbox-google-switch:before,
.checkbox-google
  input[type="checkbox"]:not(:disabled):focus
  + .checkbox-google-switch:before {
  animation: checkbox-active-on 0.5s forwards linear;
}

@keyframes checkbox-active-on {
  0% {
    box-shadow: 0 0 0 0 rgba(212, 212, 212, 0);
  }

  99% {
    box-shadow: 0 0 0 10px rgba(212, 212, 212, 0.5);
  }
}

.checkbox-google
  input[type="checkbox"]:not(:disabled):checked:active
  + .checkbox-google-switch:before,
.checkbox-google
  input[type="checkbox"]:not(:disabled):checked:focus
  + .checkbox-google-switch:before {
  animation: checkbox-active-off 0.5s forwards linear;
}

@keyframes checkbox-active-off {
  0% {
    box-shadow: 0 0 0 0 rgba(154, 190, 247, 0);
  }

  99% {
    box-shadow: 0 0 0 10px rgba(154, 190, 247, 0.5);
  }
}

/* Disabled */
.checkbox-google input[type="checkbox"]:disabled + .checkbox-google-switch {
  filter: grayscale(60%);
  border-color: rgba(0, 0, 0, 0.1);
}

.checkbox-google
  input[type="checkbox"]:disabled
  + .checkbox-google-switch:before {
  background: #eee;
}
</style>