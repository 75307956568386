<template>
  <div class="delivery">
    <div class="delivery__content content">
      <!-- <BreadCrumbs :items="[[payment.title, '/payment']]" /> -->
      <div class="bread"> 
          <router-link to="/" style="color: black"><p>{{ $t('main') }}</p></router-link>
         <div style="opacity: 0.3;">•</div>
         <p style="opacity: 0.7;">{{ $t('payment') }}</p>
      </div>

      <div v-for="(item, idx) in payment" :key="item.id">
        <div class="delivery__content__main">
          <div class="delivery__content__main-text">
            <p style="font-weight: 600;
            font-size: 32px;
            color: #202020;
            margin-bottom: 20px;">{{ item.title }}</p>
            <div v-html="item.description" class="delText">
            </div>
          </div>
          <div class="delivery__content__main__content">
            <img :src="$cdn + item.image" />
          </div>
        </div>
        <div class="delLine" v-if="++idx !== payment.length"></div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/UI/BreadCrumbs.vue";

export default {
  data() {
    return {
      isActiveMenu: 1,
      payment: null,
      isTitle: this.$t("cooperation"),
      active_answer: 0,
      answer: [
        {
          id: 0,
          title: "Дилеры и партнеры",
          text: "Повседневная практика показывает, что существующая теория представляет собой интересный эксперимент проверки анализа существующих паттернов поведения. Сложно сказать, почему явные признаки победы институционализации обнародованы. Сложно сказать, почему активно развивающиеся страны третьего мира, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут ассоциативно распределены по отраслям.",
        },
        {
          id: 1,
          title: "Предприятий из различных отраслей промышленности",
          text: "Повседневная практика показывает, что существующая теория представляет собой интересный эксперимент проверки анализа существующих паттернов поведения. Сложно сказать, почему явные признаки победы институционализации обнародованы. Сложно сказать, почему активно развивающиеся страны третьего мира, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут ассоциативно распределены по отраслям.",
        },
        {
          id: 2,
          title: "Автосервисов и пунктов замены масла (ПЗМ)",
          text: "Повседневная практика показывает, что существующая теория представляет собой интересный эксперимент проверки анализа существующих паттернов поведения. Сложно сказать, почему явные признаки победы институционализации обнародованы. Сложно сказать, почему активно развивающиеся страны третьего мира, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут ассоциативно распределены по отраслям.",
        },
        {
          id: 3,
          title: "Оптовых торговых точек",
          text: "Повседневная практика показывает, что существующая теория представляет собой интересный эксперимент проверки анализа существующих паттернов поведения. Сложно сказать, почему явные признаки победы институционализации обнародованы. Сложно сказать, почему активно развивающиеся страны третьего мира, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут ассоциативно распределены по отраслям.",
        },
      ],
    };
  },
  components: { BreadCrumbs },
  created() {    
    this.$axios.get('get-pages?lang=ru&slug=payment')
      .then(res => {
        this.payment = res.data.pages
      })
      .catch(err => {
        // console.log(err);
      })
  }
};
</script>

<style lang="scss" scoped>
.delText {
  overflow-y: auto;
  height: 400px;
    padding-right: 20px;
    margin-right: 20px;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 15px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 15px;
    height: 10px;
    outline-style: double;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background: #05630E;
    transform: scale(1.5); 
  }

/* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #117b1b;
  }
}
.delLine {
  margin-top: 60px;
  background: #00000080;
  height: 1px;
}
.bread {
  display: flex;
  gap: 5px;
}
.active_answer {
  transform: rotate(180deg);
}
.active_title {
  margin: 32px 0;
}
.active_menu {
  background: #efeded !important;
}

.delivery {
  width: 100%;
  padding: 52px 0 80px 0;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    &__main {
      display: flex;
      align-items: flex-start;
      gap: 4%;
      justify-content: space-between;

      @media screen and (max-width: 991px) {
        flex-direction: column;
        &-text {
          width: 100% !important;
          margin-bottom: 20px;
        }
        &__content {
          width: 100% !important;
        }
      }
      &-text {
        width: 57%;
        // p {
        //   font-weight: 600;
        //   font-size: 32px;
        //   color: #202020;
        //   margin-bottom: 20px;
        //   margin-top: 0;
        // }
        // div {
        //   font-weight: 400;
        //   font-size: 16px;
        //   color: #727272;
        // }
      }
      &__content {
        width: 39%;
        margin-top: 52px;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
</style>