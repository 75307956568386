<template>
  <div class="home">
    <div class="content">
      <div class="home__wrapper">

        <div class="home__search mobile">
          <input
            @keyup.enter="searchResult(siteSearch)"
            :placeholder="$t('search')"
            type="text"
            v-model="siteSearch"
          />
          <div class="home__search-btn">
            <div
              class="home__search-icon"
              @click.stop="searchResult(siteSearch)"
            >
              <img src="../assets/icons/search_white.png" alt="" />
            </div>
          </div>
        </div>

        <section class="home__main bannerStylesMain">
          <swiper
            class="home__sales-item"
            :pagination="swiper.pagination_main"
            :navigation="swiper.mainNavigation"
            :breakpoints="swiper.breakpoint_main"
            :allowTouchMove="true"
            :initialSlide="0"
            :lazy="true"
            :loop="false"
            style="padding: 0 !important"
          >
            <swiper-slide
              v-for="item in main_banners"
              :autoHeight="true"
              :key="item.id"
            >
              <div class="home__main-bg">
                <img class="imageDesctop" :src="$cdn + item.image" alt=""/>
                <img
                  class="imageMobile"
                  :src="$cdn + item.image"
                  alt=""
                />
              </div>
              <div class="home__main-content">
                <h2 class="home__title">{{ item.title }}</h2>
                <div class="home__line"></div>
                <h3 class="home__subtitle">{{ item.content }}</h3>
                <router-link :to="`/${item.url === null ? 'catalog' : item.link}`"
                  class="button button_home button_home-white"
                  style="white-space: nowrap"
                >
                  {{ $t("toCatalog") }}
                </router-link>
              </div>
            </swiper-slide>
          </swiper>
            <div class="home__main-arrows">
              <div class="home__main-left">
                <img src="../assets/icons/arrowBanner.png" alt="" />
              </div>
              <div class="home__main-right">
                <img src="../assets/icons/arrowBanner.png" alt="" />
              </div>
            </div>
        </section>


        <section>
          <div class="block2">
              <div  class="block2__box" :style="{ backgroundImage: 'url(' + require('@/assets/img/'+ 'block2v1' +'.png') + ')' }">
                <p>Pasta</p>
              </div>
              <div  class="block2__box" :style="{ backgroundImage: 'url(' + require('@/assets/img/'+ 'block2v2' +'.png') + ')' }">
                <p>Dairy product</p>
              </div>
              <div  class="block2__box" :style="{ backgroundImage: 'url(' + require('@/assets/img/'+ 'block2v3' +'.png') + ')' }">
                <p>Vegetables and fruits</p>
              </div>
          </div>
        </section>

        <section class="home__sales">
          <div class="home__sales-up">
            <router-link to="/sales" class="home__title-section" style="cursor: pointer;">
              {{ $t("sale") }}
            </router-link>
            <div class="home__sales-arrows">
              <div class="home__sales-left2">
                <img src="../assets/icons/home/arrow_left.png" alt="" />
              </div>
              <div class="home__sales-right2">
                <img src="../assets/icons/home/arrow_right.png" alt="" />
              </div>
            </div>
          </div>
          <div class="home__sales-items">
            <div class="home__slider">
              <div class="home__sales-swiper">
                <swiper
                  class="home__sales-item salePagin"
                  :space-between="20"
                  :breakpoints="swiper.breakpoints_good_card"
                  :autoplay="autoplay"
                  :watchSlidesProgress="true"
                  :allowTouchMove="true"
                  :lazy="true"
                  :navigation="swiper.salesNavigation"
                  :loop="false"
                >
                  <swiper-slide v-for="item in products.sale" :key="item.id">
                    <Card :typeSale="true" :product="item"></Card>
                  </swiper-slide>
                </swiper>
              </div>
            </div>
          </div>
        </section>

        <section class="home__category popCat">
          <div class="home__sales-up">
            <h2 class="home__title-section">
              {{ $t("popularCategories") }}
            </h2>
            <div class="home__sales-arrows home__sales-arrowsPopCat">
              <div class="home__sales-left1">
                <img src="../assets/icons/home/arrow_left.png" alt="" />
              </div>
              <div class="home__sales-right1">
                <img src="../assets/icons/home/arrow_right.png" alt="" />
              </div>
            </div>
          </div>
          <swiper
            class="home__category-items popCatPadding"
            :space-between="25"
            :breakpoints="swiper.breakpoints_category"
            :autoplay="autoplay"
            :watchSlidesProgress="true"
            :allowTouchMove="true"
            :lazy="true"
            :navigation="swiper.categoryNavigation"
            :loop="false"
          >
            <swiper-slide
              class="home__category-item popCatBack"
              v-for="item in category"
              :key="item.id"
              style="height: auto"
            >
              <div class="popCatBlockAll">
              
                <div class="home__category-text">
                  {{ item.title }}
                </div>
                <div class="home__category-img">
                  <img
                    v-if="item.image != null"
                    :src="$cdn + item.image"
                    alt=""
                  />
                </div>
                <router-link :to="('catalog/' + item.slug)" class="home__category-button popCatButton">{{ $t('goCatalog') }}</router-link>
              </div>
            </swiper-slide>
          </swiper>
        </section>

         <section class="home__brands-swiper home__brands-swiperShop1">
          <div class="home__title-section">{{ $t("shop") }}</div>
          <div class="home__slider">
            <div class="home__brands-swiper home__brands-swiperShop">
              <swiper
                class="home__shop-item shopGapper"
                :space-between="30"
                :breakpoints="swiper.breakpoints1"
                :lazy="true"
                :navigation="swiper.shopNavigation"
                :loop="false"
                :autoplay="swiper.autoplay"
              >
                <swiper-slide
                  v-for="(item, i) in shop"
                  :key="i"
                  @click="routeToShopFullPage(item.name)"
                >
                  <div class="home__brands-img shopBorder">
                    <img :src="$cdn + item.icon" alt="Img" />
                  </div>
                </swiper-slide>
              </swiper>
              <div class="home__shop-left">
                <img src="../assets/icons/home/arrow_left.png" alt="Arrow" />
              </div>
              <div class="home__shop-right">
                <img src="../assets/icons/home/arrow_right.png" alt="Arrow" />
              </div>
            </div>
          </div>
        </section>

        <section class="home__brands" v-if="brands.length">
          <div class="home__title-section">{{ $t("brand") }}</div>
          <div class="home__slider">
            <div class="home__brands-swiper" >
              <swiper
                class="home__brands-item effff"
                :space-between="30"
                :breakpoints="swiper.breakpoints"
                :lazy="true"
                :navigation="swiper.brandNavigation"
                :loop="true"
                :autoplay="swiper.autoplay1"
              >
                    <swiper-slide v-for="item in brands" :key="item.id">
                      <div class="home__brands-img brandsHome">
                        <img :src="$cdn + item.icon" alt="Img" />
                      </div>
                    </swiper-slide>
              </swiper>
              <!-- <div class="home__brands-left">
                <img src="../assets/icons/arrow_left.png" alt="Arrow" />
              </div>
              <div class="home__brands-right">
                <img src="../assets/icons/arrow_right.png" alt="Arrow" />
              </div> -->
            </div>
          </div>
        </section>

        <section class="home__about">
          <div class="home__title-section" style="text-align: center">
            {{ $t("whyUs") }}
          </div>
          <div class="home__about-items">
            <div class="home__about-item" v-for="item in about" :key="item.id">
              <div class="home__about-img">
                <img :src="$cdn + item.icon" alt="" />
              </div>
              <div class="home__about-title">{{ item.title }}</div>
              <div class="home__about-text" v-html="item.description" />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import testProducts from '@/data/products.json'
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
// props: ['image', 'salePersents', 'price', 'discription', 'count', 'salePrice', 'link'],
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);
import Card from "../components/GoodsCard.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: { Swiper, SwiperSlide, Card },
  data() {
    return {
      testProducts: testProducts.products,
      block3Value: 1,
      some_product: [],
      brands: [],
      shop: [],
      category: [],
      about: [],
      siteSearch: "",
      products: [],
      banners: [],
      main_banners: [],
      cards: [
        {
          id: 0,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 1,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 2,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 3,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 4,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 5,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 6,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 7,
          image: require("../assets/img/card/image.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490",
          count: 12,
          link: "product",
          fix_count: 12,
        },
      ],
      swiper: {
        breakpoints: {
          320: {
            slidesPerView: 2,
            spaceBetween: -20,
          },
          400: {
            slidesPerView: 3,
            spaceBetween: -20,
          },
          770: {
            slidesPerView: 4,
          },
          991: {
            slidesPerView: 5,
          },
          1200: {
            slidesPerView: 5,
          },
        },
        breakpoints1: {
          320: {
            slidesPerView: 2,
          },
          770: {
            slidesPerView: 6,
          },
          991: {
            slidesPerView: 7,
          },
          1200: {
            slidesPerView: 6,
          },
        },
        breakpoint_main: {
          320: {
            slidesPerView: 1,
          },
        },
        breakpoints_good_card: {
          320: {
            slidesPerView: 2,
            spaceBetween: 9,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 9,
          },
          991: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 78,
          },
        },
        breakpoints_category: {
          320: {
            slidesPerView: 2,
            spaceBetween: 9,
          },
          768: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 4,
          },
        },
        brandNavigation: {
          prevEl: ".home__brands-left",
          nextEl: ".home__brands-right",
        },
        shopNavigation: {
          prevEl: ".home__shop-left",
          nextEl: ".home__shop-right",
        },
        mainNavigation: {
          prevEl: ".home__main-left",
          nextEl: ".home__main-right",
        },
        salesNavigation: {
          prevEl: ".home__sales-left2",
          nextEl: ".home__sales-right2",
        },
        categoryNavigation: {
          prevEl: ".home__sales-left1",
          nextEl: ".home__sales-right1",
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        autoplay1: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination_main: {
          el: '.swiper-home-pagination',
          clickable: true,
          bulletActiveClass: "home__swiper-pagination-bullet-active",
          renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
          },
        },
      },
    };
  },
  head: {
    title: {
      inner: "It will be a pleasure",
    },
  },
  async created() {
    this.SET_LOADING(true)
    await this.$axios
      .get(`V1/brands`)
      .then(res => {
        this.SET_LOADING(false)
        res.data.data.forEach(item => {
          if (item.status === 1) {
            this.brands.push(
              {
                icon: item.image,
                id: item.id,
                status: item.status,
                title: item.title
              }
            )
            item.items.forEach(elem => {
              elem.status === 1 ? this.brands.push(elem) : ''
            })
          }
        });
        
      })
      .catch(e => {
        this.SET_LOADING(true)
        // console.log(e);
      });
    await this.setProductList();
    this.SET_LOADING(true)
    await this.$axios
      .get(`V1/home-page`)
      .then((response) => {
        this.SET_LOADING(false)
        // this.brands = response.data.data.brands;
        this.banners = response.data.data.banners;
        this.main_banners = response.data.data.slider;
        // const firstElement = this.main_banners.shift()
        // this.main_banners.push(firstElement)
        this.shop = response.data.data.shops;
        this.products = response.data.data.products;
        this.about = response.data.data["why-wes"];
        this.category = response.data.data.categories;
      })
      .catch((e) => {
        this.SET_LOADING(true)
        // console.log(e);
      });
    this.about = this.about.reverse();
  },
  methods: {
    ...mapMutations(['SET_LOADING']),
    routeToShopFullPage(id) {
      // console.log("routeToShopFullPage");
      this.$router.push("/single-shop/" + id);
    },
    async searchResult(value) {
      if (value) {
        await this.SET_SEARCH_VALUE_TO_VUEX(value);
        this.$router.push("/search/" + value);
      } else {
        // console.log("Вы ничего не написали");
      }
    },
    async setProductList() {
      await this.get_product_list();
      this.cards = this.$store.state.product;
    },

    ...mapActions({
      add_basket_product: "ADD_PRODUCT_TO_BASKET",
      get_product_list: "GET_PRODUCT_LIST",
      SET_SEARCH_VALUE_TO_VUEX: "SET_SEARCH_VALUE_TO_VUEX",
    }),
    openBlock3(value) {
      this.block3Value = value;
    }
  },
};
</script>
<style lang="scss" scoped>
.block2 {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    margin-top: 25px;
  }
  &__box {
    cursor: pointer;
    background-size: 100% 100%;
    background-position: center;
    transition: all 0.5s ease;
    background-repeat: no-repeat !important;
    border-radius: 12.8px;
    width: 31.61%;
    height: 14.37vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    color: #FFFFFF;    
    font-size: min(max(14px, calc(0.875rem + ((1vw - 3.9px) * 1.4379))), 36px);
    &:hover {
      background-size: 105% 105%; 
    }
    @media screen and (max-width: 768px) {
      text-align: center;
      height: 16.37vw;
    }
  }
}
.popCat {
  padding: 80px 0 0 0;
  position: relative;
  @media screen and (max-width: 768px)  {
    padding: 15px 0 0 0;
  }
}
.home__category-items {
  @media screen and (max-width: 992px) {
    margin-top: 0;
  }
}

.button_category {
  @media screen and (max-width: 992px) {
    width: 43.15% ;
    margin-top: 10px;
  }
}

.home__title {
  text-align: start;
  font-weight: 500;
  color: black;
  margin: 0;
}
.home__subtitle {
  text-align: start;
  color: black;
  margin-top: 30px;
}
.home__line {
  height: 7px;
  // margin-right: auto;
  // margin-left: auto;
  margin-top: 22px;
  background: #05630E;
  width: 15.09%;
}
.button_home-white {
  font-weight: 400;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
  width: 57.09%;
  margin-top: 0px;
  justify-content: center;
  background: #05630E;
border-radius: 15px;
  color: white;
    font-size: min(max(14px, calc(0.875rem + ((1vw - 3.2px) * 0.1538))), 16px);
    transition: all 0.5s ease;
    @media (max-width: 992px) {
      width: fit-content;
      padding: 0 2%;
    }
    &:hover {
      transform: scale(1.03);
    }
}
.block3 {
  background-repeat: no-repeat;
  background-size: cover;
  &__top {
    // padding: 10px 0 23px 0;
    background: transparent;
    display: flex;
    // height: 73.5%;
    gap: 15px;
    justify-content: space-between;
    @media screen and (max-width: 992px) {
      flex-direction: column;
    }
    &__left {
      padding: 2% 0;
      margin-left: 2.8%;
        width: 45%;
        @media screen and (max-width: 992px) {
          margin-left: 0%;
          width: 100%;
          padding: 8px;
        }
        p {
          font-weight: 300;
          font-family: 'Poppins', sans-serif;
          line-height: 145%;
          height: 104px;
          color: white;
            font-size: min(max(18px, calc(1.125rem + ((1vw - 12px) * 2.5))), 36px);
          @media screen and (max-width: 992px) {
            line-height: normal;
          }
        }
        button {
          border: none;
          cursor: pointer;
          background: #FFFFFF;
          height: 47px;
          width: fit-content;
          padding: 0 7%;
        }
        &-desc {
          line-height: 16px !important;
          height: 39px !important;
          margin: 2% 0;
            font-size: min(max(11px, calc(0.6875rem + ((1vw - 12px) * 0.9722))), 18px) !important; 
        }
        img {
          width: 40%;
        }
    }
    &-line {
      opacity: 0.6;
      background: #FFFFFF;
      width: 1px;
      @media screen and (max-width: 992px) {
        width: 100%;
        height: 1px;
      }
    }
    &__right {
        width: 45%;
        @media screen and (max-width: 992px) {
          width: 100%;
        }
        p {
          font-weight: 600;
          font-family: 'Poppins', sans-serif;
          color: white;
          font-size: min(max(28px, calc(1.75rem + ((1vw - 3.2px) * 0.6154))), 36px);
        }
        img {
          object-fit: fill;
          width: 100%;
          height: 100%;
        }
    }
  }
  &__bot {
    display: flex;
    justify-content: space-between;
    &-box1, &-box2, &-box3{
      background: white;
      cursor: pointer;
      width: 33.3333%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: auto;
        @media screen and (max-width: 992px) {
          height: auto;
          width: 33%;
          padding: 8px 0;
        }
      }
    }
    &-box2 {
       background-color: rgba(255, 255, 255, 0.45);
    }
    &-box3 {
       background-color: rgba(255, 255, 255, 0.08);
    }
  }
}

.brandsHome {
  margin-left: 30px;
  height: min(max(75px, calc(4.6875rem + ((1vw - 3.2px) * 4.6875))), 150px);
  width: fit-content;
  background: #FFFFFF;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  transform: rotate(45deg);
  img {
    user-select: none;
    width: min(max(70px, calc(4.375rem + ((1vw - 3.2px) * 4.6875))), 145px);
    height: min(max(70px, calc(4.375rem + ((1vw - 3.2px) * 4.6875))), 145px);
    border-radius: 50%;
    rotate: -45deg;
  }
}
.effff {
  // margin: 28px 0;
  padding: 30px 0;
}

.home__sales-left1, .home__sales-right1, .home__shop-left,.home__shop-right, .home__sales-left2,.home__sales-right2 {
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  padding: 18px 15px;
  position: absolute;
  left: -35px;
  top: 55%;
  filter: none;
  img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(51deg) brightness(106%) contrast(110%);
  }
  @media screen and (max-width: 992px) {
    display: none;
  }
}
.home__sales-right2 {
  user-select: none;
  position: absolute;
  top: 55%;
  left: auto;
  right: -35px !important;
}
.home__sales-right1 {
  user-select: none;
  position: absolute;
  top: 63%;
  left: auto;
  right: -35px !important;
}
.home__sales-left1 {
  top: 63%;
}
.home__shop-left {
  top: 38%;
  padding: 11px 10px !important;
}
.home__shop-right {
  padding: 11px 10px !important;
  position: absolute;
  top: 38%;
  left: auto;
  right: -35px !important;
}


.home__category-img {
  width: 85%;
  height: auto;
  position: relative;
  @media (max-width: 768px) {
    height: 60%;
  }
  &:after {
      transform: scale(1);
      z-index: 2;
      content: url('@/assets/icons/krugCategory.png');
      @media (max-width: 1620px) {
        transform: scale(0.9);
      }
      @media (max-width: 1420px) {
        transform: scale(0.9);
      }
      @media (max-width: 1330px) {
        transform: scale(0.8);
      }
      @media (max-width: 1025px) {
        transform: scale(0.6);
      }
      // @media (max-width: 768px) {
      //   transform: scale(0.9);
      // }
      @media (max-width: 515px) {
        transform: scale(0.45);
      }
      @media (max-width: 425px) {
        transform: scale(0.45);
      }
      @media (max-width: 330px) {
        transform: scale(0.4);
      }
  }
  img {
    z-index: 1;
    width: 82%;
    height: 90%;
    -o-object-fit: contain;
    object-fit: contain;
    position: absolute;
    left: 10%;
  }
}
.imageDesctop {
  border-radius: 15px;
  height: 720px;
  object-fit: cover;
}

.shopBorder {
  height: 150px !important;
  background: rgba(153, 153, 153, 0.15);
  box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  border-right: 10px solid #00000066;
}
.popCatPadding {
  padding: 8px;
}
.popCatBack {
  padding: 36px 0;
  cursor: default !important;
  background-image: url('@/assets/img/popCardGraphit.png');
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 1620px) {
    padding: 22px 0;
  }
}
.popCatButton {
  cursor: pointer;
  font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.1538))), 13px) !important;
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
  transition: all 0.5s ease;
  &:hover {
    transform: scale(1.05);
  }
}
.salePagin {
  padding: 20px;
  @media screen and (max-width: 992px) {
    padding: 5px;
  }
}
.home__main-right {
  background: rgba(0, 0, 0, 0.3);
  height: 94.5%;
  width: 0%;
  transition: all 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 1;
  position: absolute;
  -webkit-clip-path: polygon(0 50%, 100% 100%, 100% 0);
  clip-path: polygon(0 50%, 100% 100%, 100% 0);
  img {
    user-select: none;
    -webkit-user-drag: none;
    margin-left: 15%;
    width: auto;
    height: auto;
    transition: 0.5s ease;
    opacity: 0;
  }
  right: 0%;
}
.home__main-left {
  background: rgba(0, 0, 0, 0.3);
  height: 94.5%;
  width: 0%;
  transition: all 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 1;
  position: absolute;
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 50%);
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  img {
    rotate: 180deg;
    user-select: none;
    -webkit-user-drag: none;
    margin-right: 15%;
    width: auto;
    height: auto;
    transition: 0.5s ease;
    opacity: 0;
  }
  left: 0%;
}
.home__sales-item {
  cursor: grab;
  position: relative;
}
.bannerStylesMain {
  .swiper-button-disabled {
  display: none;
}
}
.bannerStylesMain:hover {
  .home__main-right {
      width: 4.31%;
      img {
         opacity: 1;
      }
  }
  .home__main-left {
      width: 4.31%;
      img {
         opacity: 1;
      }
  }
}
.home__main-right, .home__main-left {
  @media (max-width: 991px) {
    img {
      width: 50%;
    }
  }
}

.popCatBlockAll {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: space-between;
  width: 100%;
  height: min(max(180px, calc(11.25rem + ((1vw - 3.2px) * 9.25))), 328px);
  @media (max-width: 1400px) {
    height: auto;
  }
  @media (max-width: 991px) {
    height: min(max(180px, calc(11.25rem + ((1vw - 3.2px) * 9.25))), 328px);
  }
}
.shopGapper {
  padding: 8px 0;
}
.home__main-content  {
  @media (max-width: 768px) {
    border-radius: 5px;
    padding: 2% 4%;
  }
}
.home__line {
  @media (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.button_home-white {
  @media (max-width: 768px) {
    border-radius: 5px;
  }

}
.home__search input {
  @media (max-width: 768px) {
    border-radius: 10px 0 0 10px;
  }
}
.home__about {
  @media (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 0px;
  }
}
.imageMobile {
  border-radius: 10px;
}
</style>
