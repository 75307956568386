<template>
  <div class="shop-card">
    <div class="shop-card__up">
      <div class="shop-card__img" v-if="shop?.image">
        <img :src="require('@/assets/test/markets/' + shop?.image + '.png')" alt=""/>
      </div>
      <div class="shop-card__info">
        <div class="shop-card__title">
          {{ shop?.title }}
        </div>
        <div class="shop-card__subtitle">
          {{ shop?.desc }}
        </div>
        <div class="shop-card__rating">
          <div class="shop-card__icon">
            <img src="../assets/icons/card/star_filled.svg" alt="" />
          </div>
          <span> {{ shop?.rating }} / 5 </span>
        </div>
      </div>
      <div
        class="card__like"
        @click="getIsAuth ? pressLikeIcon(shop.id) : notWishlist()"
        :class="{ liked: productData.isLiked == true && getIsAuth}"
      ></div>
    </div>
    <div class="shop-card__items">
      <div class="shop-card__item">
        <div class="shop-card__icon">
          <img src="../assets/icons/card/bill.svg" alt="" />
        </div>
        <div class="shop-card__text">
          {{ $t("minDelivery") }} {{ shop?.min_delivery }}
        </div>
      </div>
      <div class="shop-card__item">
        <div class="shop-card__icon">
          <img src="../assets/icons/basket/car.svg" alt="" />
        </div>
        <div class="shop-card__text">
          {{ this.$t("delivery") }} - {{ shop?.date_delivery_from }}, от {{ shop?.date_delivery }}
        </div>
      </div>
      <div class="shop-card__item">
        <div class="shop-card__icon">
          <img src="../assets/icons/location_black.png" alt="" />
        </div>
        <div class="shop-card__text">
          {{ shop?.address }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const toaster = createToaster({ position: "top-right" });
import { createToaster } from "@meforma/vue-toaster";
export default {
  data() {
    return {
      productData: {
        id: this.id,
        img: this.image,
        name: this.name,
        category: this.category,
        rating: this.rating,
        minAmount: this.minAmount,
        deliveryTime: this.deliveryTime,
        deliveryCost: this.deliveryCost,
        city: this.city,
        isLiked: this.isLiked,
      },
    };
  },
  props: [
    "shop"
  ],
  computed: {
    ...mapGetters([
      "getIsAuth"
    ]),
  },
  methods: {
    openFullShop(id) {
      // console.log("open fullshop is work", this.productData);
      this.GET_MARKET_ITEM_SINGLE(this.productData);
      this.$router.push("/single-shop/" + id);
    },
    notWishlist() {
      toaster.error(this.$t('notAddWishlist'));
    },
    ...mapActions([
      "GET_MARKET_ITEM",
      "GET_MARKET_ITEM_SINGLE",
      "DELETE_MARKET_ITEM",
    ]),
    pressLikeIcon(id) {
      this.productData.isLiked = !this.productData.isLiked;
      if (this.productData.isLiked == true) {
        this.$emit("addLikeCard", this.productData);
        this.GET_MARKET_ITEM(this.productData);
      } else {
        this.DELETE_MARKET_ITEM(id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-card {
  position: relative;
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 991px) {
    padding: 9px;
  }

  &__up {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #202020;

    &:hover {
      cursor: pointer;
    }
  }

  &__subtitle {
    margin-top: 2px;
    font-weight: 400;
    font-size: 16px;
    color: #727272;
  }

  &__rating {
    margin-top: 2px;
    display: flex;
    align-items: center;
    gap: 0 5px;
    font-weight: 400;
    font-size: 16px;
    color: #727272;
  }

  &__img {
    width: 80px;
  }

  &__icon {
    width: 20px;
  }

  &__item {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
</style>