import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Basket from "../views/Basket.vue";
import Product from "../components/ProductPage.vue";
import ProductQuestions from "../components/ProductQuestions.vue";
import Catalog from "../views/CatalogPage.vue";
import Registration from "../views/RegistrationView.vue";
import Login from "../views/RLoginView.vue";
import Order from "../views/order/GetOrder.vue";
import EndOrder from "../views/order/EndOrder.vue";
import SingleCategory from "../views/SingleCategory.vue";
import SingleShop from "../views/SingleShop.vue";
import MarketShop from "../views/Market.vue";
// import FAQ from "../views/FAQ.vue";
import Payment from "../views/Payment.vue";
import Delivery from "../views/Delivery.vue";
import Cooperation from "../views/Cooperation.vue";
import Error from "../views/ErrorPage.vue";
import Contact from "../views/Contact.vue";
import About from "../views/About.vue";
import News from "../views/News.vue";
import FullNews from "../views/FullNews.vue";
import Sale from "../views/SalePage.vue";
import Search from "../views/SearchPage.vue";
import Liked from "../views/Liked.vue";
// personal data
import PersonalData from "../views/PersonalData.vue";
import Confidential from "../views/Confidential.vue";

//acc
import AccMain from "../views/acc/Main.vue";
import AccMobile from "../views/acc/AccMobile.vue";
import AccLiked from "../views/acc/Liked.vue";
import AccProfile from "../views/acc/Profile.vue";
import AccOrders from "../views/acc/Orders.vue";
import AccSales from "../views/acc/Sales.vue";
import AccComments from "../views/acc/Comments.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy";
import UserAgreement from "@/views/UserAgreement";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/product/:id",
    name: "Product",
    component: Product,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/product/:id/questions",
    name: "Product questions",
    component: ProductQuestions,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/basket",
    name: "Basket",
    component: Basket,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/personal-data",
    name: "PersonalData",
    component: PersonalData,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/confidential",
    name: "confidential",
    component: Confidential,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/registration",
    name: "Registration",
    component: Registration,
    meta: {
      layout: "main",
      requiresAuth: false
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layout: "main",
      requiresAuth: false
    },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/sales",
    name: "Sales",
    component: Sale,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/sales/:id",
    name: "SalesId",
    component: Sale,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/sales/:id/:id",
    name: "SalesIdId",
    component: Sale,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/likeds",
    name: "Liked",
    component: Liked,
    meta: {
      layout: "main",
      requiresAuth: true,
    },
  },
  {
    path: "/search/:result",
    name: "Search",
    component: Search,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/my-order",
    name: "Order",
    component: Order,
    meta: {
      layout: "main",
      requiresAuth: true,
    },
  },
  {
    path: "/single-shop/:id",
    name: "SingleShop",
    component: SingleShop,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/news",
    name: "News",
    component: News,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/delivery",
    name: "Delivery",
    component: Delivery,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/news/:id",
    name: "FullNews",
    component: FullNews,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/end-order",
    name: "EndOrder",
    component: EndOrder,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/market-shop",
    name: "MarketShop",
    component: MarketShop,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/error",
    name: "Error",
    component: Error,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/catalog",
    name: "catalog",
    component: Catalog,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/catalog/:id",
    name: "catalogId",
    component: Catalog,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/catalog/:id/:id",
    name: "catalogIdId",
    component: Catalog,
    meta: {
      layout: "main",
    },
  },
  // {
  //   path: "/faq",
  //   name: "FAQ",
  //   component: FAQ,
  //   meta: {
  //     layout: "main",
  //   },
  // },
  // {
  //   path: "/faq/:slug",
  //   name: "faqSlug",
  //   component: FAQ,
  //   meta: {
  //     layout: "main",
  //   },
  // },
  {
    path: "/cooperation",
    name: "Cooperation",
    component: Cooperation,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/cooperation/:slug",
    name: "CooperationSlug",
    component: Cooperation,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/single-category",
    name: "SingleCategory",
    component: SingleCategory,
    meta: {
      layout: "main",
    },
  },

  {
    path: "/single-category/:category/:id",
    name: "SingleCategory",
    component: SingleCategory,
    meta: {
      layout: "main",
    },
  },

  //acc
  // {
  //   path: "/acc/main",
  //   name: "AccMain",
  //   component: AccMain,
  //   meta: {
  //     layout: "acc",
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/acc",
    name: "Acc",
    component: AccMobile,
    meta: {
      layout: "main",
      requiresAuth: true,
    },
  },
  {
    path: "/personal_data",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: {
      layout: "main"
    },
  },
  {
    path: "/terms",
    name: "UserAgreement",
    component: UserAgreement,
    meta: {
      layout: "main"
    },
  },
  {
    path: "/acc/liked",
    name: "AccLiked",
    component: AccLiked,
    meta: {
      layout: "acc",
      requiresAuth: true,
    },
  },
  {
    path: "/acc/profile",
    name: "AccProfile",
    component: AccProfile,
    meta: {
      layout: "acc",
      requiresAuth: true,
    },
  },
  {
    path: "/acc/orders",
    name: "AccOrders",
    component: AccOrders,
    meta: {
      layout: "acc",
      requiresAuth: true,
    },
  },
  {
    path: "/acc/sales",
    name: "AccSales",
    component: AccSales,
    meta: {
      layout: "acc",
      requiresAuth: true,
    },
  },
  {
    path: "/acc/comments",
    name: "AccComments",
    component: AccComments,
    meta: {
      layout: "acc",
      requiresAuth: true,
    },
  },
  {
  path: '/:catchAll(.*)',
    name: "404",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("access_token")) {
      next();
    } else {
      next({ name: "Home" });
    }
  } else {
    next();
  }
});

export default router;
