<template>
    <Line 
    :chart-options="chartOptions"
    :label="label"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height" />
</template>
  
<script>
import { Line } from "vue-chartjs";
import Chart from "chart.js/auto";
import axios from 'axios'

export default {
    name: 'BarChart',
    components: { Line },
    data() {
        return {
            allStat: [],
            year: "",
            month: "",
            sdadsa: [],
            chartData: {
                labels: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ],
                datasets: [{
                    backgroundColor: '#EAEAEA',
                    borderColor: "black",
                    fill: true,
                    borderWidth: 2, 
                    pointBackgroundColor: 'white',
                    pointHoverBackgroundColor: 'white',
                    pointBorderWidth: 2,
                    pointHoverBorderWidth: 10,
                    tension: 0.4,
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,],

                }],
            },
            
                chartOptions: {
                    onClick: (event, elements) => {
                        if (elements.length > 0) {
                            var index = elements[0].index;
                            console.log(++index);
                            this.selectMonth(index)
                        }
                     },
                
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    },
                },
                scales: {
                    y: {
                        position: 'right',
                    }
                }
                
            },


        }
    },
    methods: {
        async selectMonth(value) {
            const postStat = {
                month: value,
                year: 2023
            }
            await axios.post("/V1/statistic", postStat, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
            .then(res => {
                const newStaticData = res.data.statistics
                const monthValue = this.chartData.labels[--value];
                this.$emit('static-data-updated', newStaticData)
                this.$emit('month-data-updated', monthValue);
                console.log(newStaticData);
            })
            .catch(err => {
                alert('ee')
            })
        }
    },
    async mounted() {
        await this.$axios.get('V1/statistics', {
        headers: {
            Authorization: "Bearer ".concat(
                localStorage.getItem("access_token")
            ),
        },
        })
        .then(res => {
            this.allStat = res.data.statistics;
            
            this.chartData.datasets[0].data = []
            for (var key in this.allStat['2023']) {
                this.chartData.datasets[0].data.push(this.allStat['2023'][key].price_month)
            }
            // alert('this.chartData.datasets[0].data', this.chartData.datasets[0].data)
        })
        // .catch(err => console.log(err))
        },
    props: {
        staticData: {
            type: Array
        },
        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        label: {
            type: String,
            default: 'Product',
        },
        width: {
            type: Number,
            default: 400,
        },
        height: {
            type: Number,
            default: 600
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => { }
        },
        plugins: {
            type: Object,
            default: () => { }
        }
    },
    watch: {
        label(newValue) {
            this.chartData.datasets[0].label = newValue
        }
    }

}
</script>