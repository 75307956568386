<template>
  <div class="market">
    <div class="content">
      <BreadCrumbs :items="[[$t('shop'), '/market-shop']]" />
      <div class="market__title">{{ $t("allShops") }}</div>
      <!-- <div class="market-slide">
        <div class="market-slide-wrapper">
          <swiper
            class="market-swiper"
            :breakpoints="breakpoints"
            :lazy="true"
            :space-between="30"
            :navigation="marketSwiperNavigation"
            :loop="false"
            @swiper="onSwiper"
          >
            <swiper-slide v-for="item in market_category" :key="item.id">
              <div class="market__category">
                <div
                  class="market__category-item"
                  @click="openCategory(item.id)"
                >
                  <div class="market__category-img">
                    <img :src="item.img" alt="" />
                  </div>
                  <div class="market__category-name">
                    {{ item.title }}
                  </div>
                  <div class="market__category-count">
                    ({{ shops[item.id].length }})
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="market-swiper-left">
          <img src="../assets/icons/arrow_left.png" alt="Arrow" />
        </div>
        <div class="market-swiper-right">
          <img src="../assets/icons/arrow_right.png" alt="Arrow" />
        </div>
      </div> -->
      <div>
        <div
          class="market__items"
          :class="{ market__items_left: shops[number].length < 4 }"
        >
          <div
            class="market__item"
            v-for="(item, index) in markets"
            :key="index"
          >
            <MarketCard  :shop="item"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import BreadCrumbs from "@/components/UI/BreadCrumbs.vue";
import MarketCard from "../components/MarketShopCard.vue";
import markets from '@/data/markets.json'
export default {
  data() {
    return {
      markets: markets.markets,
      breakpoints: {
        330: {
          slidesPerView: 2,
        },
        576: {
          slidesPerView: 3,
        },
        991: {
          slidesPerView: 5,
        },
        1500: {
          slidesPerView: 7,
        },
        1620: {
          slidesPerView: 8,
        },
      },
      refresh_count: true,
      marketSwiperNavigation: {
        prevEl: ".market-swiper-left",
        nextEl: ".market-swiper-right",
      },
      market_category: [
        {
          id: 0,
          img: require("../assets/icons/market/item_1.png"),
          title: this.$t("favouritesShop"),
        },
        {
          id: 1,
          img: require("../assets/icons/market/item_2.png"),
          title: "Строительные материалы",
          count: "5083",
        },
        {
          id: 2,
          img: require("../assets/icons/market/item_3.png"),
          title: "Электроинстру-менты",
          count: "5083",
        },
        {
          id: 3,
          img: require("../assets/icons/market/item_4.png"),
          title: "Электроинстру-менты",
          count: "5083",
        },
        {
          id: 4,
          img: require("../assets/icons/market/item_5.png"),
          title: "Электроинстру-менты",
          count: "5083",
        },
        {
          id: 5,
          img: require("../assets/icons/market/item_6.png"),
          title: "Электроинстру-менты",
          count: "5083",
        },
        {
          id: 6,
          img: require("../assets/icons/market/item_7.png"),
          title: "Электроинстру-менты",
          count: "5083",
        },
        {
          id: 7,
          img: require("../assets/icons/market/item_8.png"),
          title: "Электроинстру-менты",
          count: "5083",
        },
        {
          id: 8,
          img: require("../assets/icons/market/item_9.png"),
          title: "Электроинстру-менты",
          count: "5083",
        },
      ],
      number: 1,
      shops: [
        [
          {
            id: 0,
            name: "Мегастой",
            image: require("../assets/img/home/brand.png"),
            category: "Products для дома",
            rating: 2,
            minAmount: 150000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 1,
            name: "Мегастой",
            image: require("../assets/img/home/brand.png"),
            category: "Products для дома",
            rating: 2,
            minAmount: 150000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 2,
            name: "Мегастой",
            image: require("../assets/img/home/brand.png"),
            category: "Products для дома",
            rating: 2,
            minAmount: 150000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 3,
            name: "Мегастой",
            image: require("../assets/img/home/brand.png"),
            category: "Products для дома",
            rating: 2,
            minAmount: 150000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 4,
            name: "Мегастой",
            image: require("../assets/img/home/brand.png"),
            category: "Products для дома",
            rating: 2,
            minAmount: 150000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
        ],
        [
          {
            id: 5,
            name: "СтройБетон",
            image: require("../assets/img/home/brand.png"),
            category: "Products для стройки",
            rating: 3,
            minAmount: 250000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 6,
            name: "СтройБетон",
            image: require("../assets/img/home/brand.png"),
            category: "Products для стройки",
            rating: 3,
            minAmount: 250000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 7,
            name: "СтройБетон",
            image: require("../assets/img/home/brand.png"),
            category: "Products для стройки",
            rating: 3,
            minAmount: 250000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 8,
            name: "Мегастой",
            image: require("../assets/img/home/brand.png"),
            category: "Products для дома",
            rating: 2,
            minAmount: 150000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
        ],
        [
          {
            id: 9,
            name: "ЭлектроМаг",
            image: require("../assets/img/home/brand.png"),
            category: "Электроника",
            rating: 5,
            minAmount: 200100,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 10,
            name: "ЭлектроМаг",
            image: require("../assets/img/home/brand.png"),
            category: "Электроника",
            rating: 5,
            minAmount: 200100,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 11,
            name: "ЭлектроМаг",
            image: require("../assets/img/home/brand.png"),
            category: "Электроника",
            rating: 5,
            minAmount: 200100,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
        ],
        [
          {
            id: 12,
            name: "Мегастой",
            image: require("../assets/img/home/brand.png"),
            category: "Products для дома",
            rating: 2,
            minAmount: 150000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 13,
            name: "Мегастой",
            image: require("../assets/img/home/brand.png"),
            category: "Products для дома",
            rating: 2,
            minAmount: 150000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 14,
            name: "Мегастой",
            image: require("../assets/img/home/brand.png"),
            category: "Products для дома",
            rating: 2,
            minAmount: 150000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 15,
            name: "Мегастой",
            image: require("../assets/img/home/brand.png"),
            category: "Products для дома",
            rating: 2,
            minAmount: 150000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 16,
            name: "Мегастой",
            image: require("../assets/img/home/brand.png"),
            category: "Products для дома",
            rating: 2,
            minAmount: 150000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
        ],
        [
          {
            id: 17,
            name: "СтройБетон",
            image: require("../assets/img/home/brand.png"),
            category: "Products для стройки",
            rating: 3,
            minAmount: 250000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 18,
            name: "СтройБетон",
            image: require("../assets/img/home/brand.png"),
            category: "Products для стройки",
            rating: 3,
            minAmount: 250000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 19,
            name: "СтройБетон",
            image: require("../assets/img/home/brand.png"),
            category: "Products для стройки",
            rating: 3,
            minAmount: 250000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 20,
            name: "Мегастой",
            image: require("../assets/img/home/brand.png"),
            category: "Products для дома",
            rating: 2,
            minAmount: 150000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
        ],
        [
          {
            id: 21,
            name: "ЭлектроМаг",
            image: require("../assets/img/home/brand.png"),
            category: "Электроника",
            rating: 5,
            minAmount: 200100,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 22,
            name: "ЭлектроМаг",
            image: require("../assets/img/home/brand.png"),
            category: "Электроника",
            rating: 5,
            minAmount: 200100,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 23,
            name: "ЭлектроМаг",
            image: require("../assets/img/home/brand.png"),
            category: "Электроника",
            rating: 5,
            minAmount: 200100,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
        ],
        [
          {
            id: 24,
            name: "Мегастой",
            image: require("../assets/img/home/brand.png"),
            category: "Products для дома",
            rating: 2,
            minAmount: 150000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 25,
            name: "Мегастой",
            image: require("../assets/img/home/brand.png"),
            category: "Products для дома",
            rating: 2,
            minAmount: 150000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 26,
            name: "Мегастой",
            image: require("../assets/img/home/brand.png"),
            category: "Products для дома",
            rating: 2,
            minAmount: 150000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 27,
            name: "Мегастой",
            image: require("../assets/img/home/brand.png"),
            category: "Products для дома",
            rating: 2,
            minAmount: 150000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 28,
            name: "Мегастой",
            image: require("../assets/img/home/brand.png"),
            category: "Products для дома",
            rating: 2,
            minAmount: 150000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
        ],
        [
          {
            id: 29,
            name: "СтройБетон",
            image: require("../assets/img/home/brand.png"),
            category: "Products для стройки",
            rating: 3,
            minAmount: 250000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 30,
            name: "СтройБетон",
            image: require("../assets/img/home/brand.png"),
            category: "Products для стройки",
            rating: 3,
            minAmount: 250000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 31,
            name: "СтройБетон",
            image: require("../assets/img/home/brand.png"),
            category: "Products для стройки",
            rating: 3,
            minAmount: 250000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 32,
            name: "Мегастой",
            image: require("../assets/img/home/brand.png"),
            category: "Products для дома",
            rating: 2,
            minAmount: 150000,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
        ],
        [
          {
            id: 33,
            name: "ЭлектроМаг",
            image: require("../assets/img/home/brand.png"),
            category: "Электроника",
            rating: 5,
            minAmount: 200100,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 34,
            name: "ЭлектроМаг",
            image: require("../assets/img/home/brand.png"),
            category: "Электроника",
            rating: 5,
            minAmount: 200100,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
          {
            id: 35,
            name: "ЭлектроМаг",
            image: require("../assets/img/home/brand.png"),
            category: "Электроника",
            rating: 5,
            minAmount: 200100,
            deliveryTime: "21 июня",
            deliveryCost: "1000 тг",
            city: "Аксай, Уральск, Кордай",
            isLiked: false,
          },
        ],
      ],
    };
  },
  methods: {
    ...mapActions(["GET_MARKET_ITEM"]),
    openFullShop(item) {
      this.GET_MARKET_ITEM(item);
      // console.log("asdasd", item);
      this.$router.push("/single-shop/" + item.id);
    },
    onSwiper(swiper) {
      // console.log(swiper);
    },
    onSlideChange() {
      // console.log("slide change");
    },
    openCategory(id) {
      this.number = id;
      // console.log(id);
    },
  },
  components: {
    MarketCard,
    Swiper,
    SwiperSlide,
    BreadCrumbs,
  },
};
</script>

<style lang="scss" scoped>
.market-swiper {
  padding: 8px !important;
}
</style>