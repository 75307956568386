<template>
    <div class=cancelOrder v-if="$store.state.cancelOrder == true" v-scroll @click="$store.state.cancelOrder = false, resetOrders()">
        <div class="cancelOrder__content" @click.stop>
            <img
            src="@/assets/icons/close.svg"
            class="closeIcon"
            @click="$store.state.cancelOrder = false, resetOrders()"   
            />
            <p>Cancel the order</p>
            <textarea class="leQ-textarea" v-model="v$.comment.$model"></textarea>
            <p v-if="v$.comment.$error" class="errorData">
                The comment must consist of at least 10 words
            </p>
            <button @click="cancelOrder">Send</button>
        </div>
    </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
} from "@vuelidate/validators";
export default {
    props: ['orderId'],
    data() {
        return {
            v$: useVuelidate(),
            comment: "",
        }
    },
    validations() {
      return {
          comment: { required, minLength: minLength(10) },
        }
    },
      methods: {
        resetOrders() {
          this.comment = "",
          this.v$.$reset()
        },
        async cancelOrder() {
        this.v$.$validate();
        if (!this.v$.$invalid) {
            const data = {
              comment: this.comment,
              order_id: this.orderId
            }
            await this.$axios.post("/V1/cancel-order", data,  {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
            .then(res => {
                this.$store.state.cancelOrder = false;
                this.$toaster.success('Успешно отправлено!')
                this.resetOrders();
            })
            .catch(err => {
                this.$toaster.error('Check the completed fields!')
            })
        }
        else {
          this.$toaster.error('Заполните обязательные поля!')
        }
            
            
        }
    }
}
</script>
<style lang="scss" scoped>
.closeIcon {
  cursor: pointer;
  width: auto;
  position: absolute;
  right: 16px;
  top: 16px;
  transition: all 0.7s ease;
  &:hover {
    rotate: 180deg;
    transform: scale(1.1);
  }
}

.cancelOrder {
  overflow: scroll;
  height: 100vh;
  color: black;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: #f9f9fd;
    cursor: pointer;
  }
  p {
    font-size: min(max(18px, calc(1.125rem + ((1vw - 3.2px) * 0.375))), 24px);
    text-align: center;
    margin-bottom: 16px;
  }
  textarea {
    padding-top: 16px;
    padding-left: 16px;
    width: 100%;
    resize: none;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    outline: none;
  }
  button {
    cursor: pointer;
    margin-top: 17px;
    height: 35px;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    background: black;
    color: white;
    border-radius: 5px;
    font-size: min(max(11.5px, calc(0.71875rem + ((1vw - 3.2px) * 0.1563))), 14px);
    transition: all 0.5s ease;
    &:hover {
        transform: scale(1.02);
    }
  }
  &__content {
    width: 25%;
    background: white;
    position: relative;
    padding: 20px;
  }
}

.errorData {
  margin-top: 8px;
  color: red !important;
  font-size: min(
    max(10px, calc(0.625rem + ((1vw - 3.2px) * 0.1538))),
    12px
  ) !important;
}
</style>