<template>
  <div class="acc-liked__main">
    <!-- <div class="acc__title" @click="$router.go(-1)" :class="getType === 'individual' ? 'indTitle' : ''">
      <img :src="require('@/assets/icons/leftArrow.png')" alt="left" />{{ $t("myFavourites") }}
    </div> -->

    <div class="liked__buttons">
      <div
        class="liked__button"
        @click="liked_btn = 1"
        :class="{ liked__button_active: liked_btn === 1 }"
      >
        {{ $t("favouritesProducts") }}
      </div>
      <div
        class="liked__button"
        @click="liked_btn = 2"
        :class="{ liked__button_active: liked_btn === 2 }"
      >
        {{ $t("favouritesShop") }}
      </div>
    </div>
    <div class="singleCategory__main-items" v-show="liked_btn === 1">
      <div
        class="singleCategory__main-item"
        v-for="item in getLiked"
        :key="item.id">
        <Card :product="item" :likes="true"></Card>
      </div>
    </div>
    <div class="singleCategory__main-items" v-show="liked_btn === 2">
      <MarketCard :shop="item" v-for="(item, index) in markets" :key="index" class="marketCardStyle"> </MarketCard>
    </div>
    <div class="singleCategory__main-pagination" style="display: none">
      <div class="singleCategory__main-count">
        <span class="active_page">1</span>
        <span>2</span>
        <span>3</span>
        <span>4</span>
      </div>
      <div class="singleCategory__main-icon">
        <img src="../../assets/icons/components_icon/next_page.svg" alt="" />
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import Card from "../../components/GoodsCard.vue";
import MarketCard from "../../components/MarketShopCard.vue";
import markets from '@/data/markets.json'
export default {
  data() {
    return {
      markets: markets.markets,
      minPrice: 10,
      maxPrice: 1000,
      min_cost: "",
      max_cost: "",
      liked_btn: 1,
      items: [],
      // cards: [
      //   {
      //     id: 0,
      //     image: require("../../assets/img/card/image.png"),
      //     sale_persents: "30",
      //     discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
      //     price: "19 390 £/шт",
      //     old_price: "33 490 £",
      //     count: "12 шт",
      //     link: "product",
      //   },
      //   {
      //     id: 1,
      //     image: require("../../assets/img/card/image.png"),
      //     sale_persents: "30",
      //     discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
      //     price: "19 390 £/шт",
      //     old_price: "33 490 £",
      //     count: "12 шт",
      //     link: "product",
      //   },
      //   {
      //     id: 2,
      //     image: require("../../assets/img/card/image.png"),
      //     sale_persents: "30",
      //     discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
      //     price: "19 390 £/шт",
      //     old_price: "33 490 £",
      //     count: "12 шт",
      //     link: "product",
      //   },
      //   {
      //     id: 3,
      //     image: require("../../assets/img/card/image.png"),
      //     sale_persents: "30",
      //     discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
      //     price: "19 390 £/шт",
      //     old_price: "33 490 £",
      //     count: "12 шт",
      //     link: "product",
      //   },
      //   {
      //     id: 4,
      //     image: require("../../assets/img/card/image.png"),
      //     sale_persents: "30",
      //     discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
      //     price: "19 390 £/шт",
      //     old_price: "33 490 £",
      //     count: "12 шт",
      //     link: "product",
      //   },
      // ],
    };
  },
  async created() {
    window.scrollTo(0, 0);
    await this.setLikedCard();
  },
  methods: {
    async setLikedCard() {
      this.items = [];
      this.items = this.$store.state.liked;
    },
  },
  computed: {
    ...mapGetters(["getType", "getLiked"]),
  },
  components: {
    Card,
    MarketCard,
  },
};
</script>

<style lang="scss">
.liked {
  &__buttons {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__button {
    padding: 16px 24px;
    width: 217px;
    height: 54px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &_active {
      background: #efeded;
    }
  }
}

.liked__button {
  &:hover {
    cursor: pointer;
  }
}
.marketCardStyle {
  width: 30% !important;
}
.liked__button_active {
    background: rgba(5, 99, 14, 0.5);
    border-radius: 10px;
    color: white;
}
</style>
