<template>
    <div class="acc-profile">
        <div class="acc-profile__wrapper">
            <!-- <div class="acc__title" @click="$router.go(-1)" :class="getType === 'individual' ? 'indTitle' : ''">
                <img :src="require('@/assets/icons/leftArrow.png')" alt="left" />
                {{ $t("profileAccount") }}
            </div> -->
            <div class="liked__buttons">
                <div class="liked__button" @click="liked_btn = 1" :class="{ liked__button_active: liked_btn === 1 }">
                    {{ $t("personalData") }}
                </div>
                <div class="liked__button" @click="liked_btn = 2" :class="{ liked__button_active: liked_btn === 2 }">
                    {{ $t("changePassword") }}
                </div>
            </div>
            <div class="acc-profile__main" v-show="liked_btn == 1" v-if="getUser">
                <div class="acc-profile__avatar">
                    <img v-if="currentImage" :src="imagePreview" alt="avatar" class="logoProf"/>
                    <img v-else-if="!getUser.image" :src="require('@/assets/icons/user-avatar.png')" alt="avatar" />
                    <img v-else :src="$cdn + getUser.image" class="logoProf"/>
                   <label for="file" class="labelForImageProfile">
                        <input
                            type="file"
                            id="file"
                            @change="uploadF()"
                            name="file"
                            ref="file"
                        />
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="14" cy="14" r="13" fill="#282828" stroke="#282828"/>
                            <g clip-path="url(#clip0_2353_24932)">
                            <path d="M21.2933 6.70573C20.8401 6.2532 20.2258 5.99902 19.5853 5.99902C18.9449 5.99902 18.3306 6.2532 17.8773 6.70573L6 18.5831V21.9991H9.416L21.2933 10.1217C21.7457 9.66842 21.9998 9.05415 21.9998 8.41373C21.9998 7.77331 21.7457 7.15905 21.2933 6.70573ZM8.86667 20.6657H7.33333V19.1324L16.2067 10.2657L17.74 11.7991L8.86667 20.6657ZM20.3507 9.17907L18.6793 10.8504L17.1493 9.31707L18.82 7.6484C19.0233 7.44507 19.2991 7.33084 19.5867 7.33084C19.8742 7.33084 20.15 7.44507 20.3533 7.6484C20.5567 7.85173 20.6709 8.12751 20.6709 8.41507C20.6709 8.70262 20.5567 8.9784 20.3533 9.18173L20.3507 9.17907Z" fill="white"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_2353_24932">
                            <rect width="16" height="16" fill="white" transform="translate(6 6)"/>
                            </clipPath>
                            </defs>
                        </svg>
                   </label>
                </div>
                <div class="acc-profile__items">
                    <div class="acc-profile__item">
                        <div class="acc-profile__name">
                            Name
                        </div>
                        <div class="acc-profile__input">
                            <input v-model="getUser.name" type="text" />
                        </div>
                    </div>
                    <!-- <div class="acc-profile__item">
                        <div class="acc-profile__name">
                            BIN
                        </div>
                        <div class="acc-profile__input">
                            <input v-model="getUser.bin" type="text" />
                        </div>
                    </div> -->
                    <div class="acc-profile__item" :class="{urForFiz: getType == 'physical'}">
                        <div class="acc-profile__name">Surname</div>
                        <div class="acc-profile__input">
                            <input v-model="getUser.actual_address" type="text" />
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__name">E-mail</div>
                        <div class="acc-profile__input">
                            <input v-model="getUser.email" type="text" />
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        Phone number
                        <div class="acc-profile__input">
                            <input v-model="getUser.phone_number" type="text" />
                        </div>
                    </div>
                    <!-- <div class="acc-profile__item" :class="{urForFiz: getType == 'physical'}" v-if="getType !== 'physical'">
                        <div class="acc-profile__name">Legal address</div>
                        <div class="acc-profile__input">
                            <input v-model="getUser.entity_address" type="text" />
                        </div>
                    </div> -->
                    <button @click.prevent="changeUser" class="button button_auth">
                        {{ $t("save") }}
                    </button>
                </div>
            </div>
            <div class="acc-profile__main" v-show="liked_btn == 2">
                <div class="acc-profile__items2">
                    <div class="acc-profile__item">
                        <div class="acc-profile__name">Current password</div>
                        <div class="authorization-enter__password acc-profile__input">
                            <div>
                            <input :type="showPass0 ? 'password' : 'text'" placeholder="Enter the password" autocomplete="nope" v-model="v$.old_password.$model" readonly onfocus="this.removeAttribute('readonly');" />
                            <span class="eye" @click="showPass0 = !showPass0">
                                <img
                                :src="
                                    require(`@/assets/icons/${
                                    !showPass0 ? 'eyeshow' : 'eyehide'
                                    }.png`)
                                "
                                />
                            </span>
                            </div>
                        </div>
                        <div class="input-errors" v-for="(error, index) of v$.old_password.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__name">New password</div>
                        <div class="authorization-enter__password acc-profile__input">
                            <div>
                                <input :type="showPass1 ? 'password' : 'text'" v-model="v$.new_password.$model" placeholder="Enter the password" autocomplete="nope" readonly onfocus="this.removeAttribute('readonly');" />
                                <span class="eye" @click="showPass1 = !showPass1">
                                          <img
                                            :src="
                                              require(`@/assets/icons/${
                                                !showPass1 ? 'eyeshow' : 'eyehide'
                                              }.png`)
                                            "
                                          />
                                        </span>
                            </div>
                        </div>
                        <div class="input-errors" v-for="(error, index) of v$.new_password.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                    <div class="acc-profile__item">
                        <div class="acc-profile__name">New password again</div>
                        <div class="acc-profile__input authorization-enter__password">
                            <div>
                                <input :type="showPass ? 'password' : 'text'" v-model="v$.new_password_confirmation.$model" placeholder="Enter the password" autocomplete="nope" readonly onfocus="this.removeAttribute('readonly');" />
                                <span class="eye" @click="showPass = !showPass">
                                          <img
                                            :src="
                                              require(`@/assets/icons/${
                                                !showPass ? 'eyeshow' : 'eyehide'
                                              }.png`)
                                            "
                                          />
                                        </span>
                            </div>
                        </div>
                        <div class="input-errors" v-for="(error, index) of v$.new_password_confirmation.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                    <button class="button button_auth" @click="newPassword">
                                    {{ $t("changePassword") }}
                                  </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, sameAs, minLength, helpers } from "@vuelidate/validators";
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: "top-right" });

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    directives: { mask },
    data() {
        return {
            liked_btn: 1,
            showPass: true,
            showPass1: true,
            currentImage: false,
            imagePreview: null,
            uploadFile: "",
            showPass0: true,
            old_password: "",
            ld: {
                companyName: "",
                bin: "",
                email: "",
                phone: "",
                lawAddress: "",
                factAddress: "",
            },
            new_password: "",
            new_password_confirmation: "",
        };
    },
    validations() {
        return {
            old_password: {
                required: helpers.withMessage("This is a required field", required),
                minLength: helpers.withMessage("Минимум 8 символов", minLength(8)),
            },
            new_password: {
                required: helpers.withMessage("This is a required field", required),
                minLength: helpers.withMessage("Минимум 8 символов", minLength(8)),
            },
            new_password_confirmation: {
                required: helpers.withMessage("This is a required field", required),
                minLength: helpers.withMessage("Минимум 8 символов", minLength(8)),
                sameAs: helpers.withMessage(
                    "Пароли не совпадают",
                    sameAs(this.new_password)
                ),
            },
        };
    },
    watch: {
        liked_btn() {
            this.old_password =  ""
            this.new_password =  "",
            this.new_password_confirmation =  ""
            this.v$.$reset(),
            this.showPass =  true,
            this.showPass1 = true,
            this.showPass0 = true
        }
    },
    methods: {
        uploadF() {
            this.uploadFile = this.$refs.file.files['0'];
            let reader = new FileReader();
            reader.onload = () => {
                this.imagePreview = reader.result;
            };
            reader.readAsDataURL(this.uploadFile);
            console.log(reader);
            this.currentImage = true
        },
        async changeUser() {
            let changeDataProfile = new FormData()
            changeDataProfile.append("name", this.getUser.name)
            changeDataProfile.append("email", this.getUser.email)
            changeDataProfile.append("phone", this.getUser.phone_number)
            changeDataProfile.append("bin", this.getUser.bin)
            changeDataProfile.append("role", this.getUser.role)
            changeDataProfile.append("image", this.uploadFile)
            await this.$axios.post("/V1/user-update", changeDataProfile, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
              .then(res => {
                this.$toaster.success('Успешно изменен!')
                location.reload();
              })
              .catch(err => {
                this.$toaster.error('Check the completed fields!')
            })
        },
        async newPassword() {
            this.v$.$validate();
            if (this.v$.$invalid) {
                toaster.warning("Check the completed fields");
            } else {
                let data = new FormData();
                data.append("old_password", this.old_password);
                data.append("new_password", this.new_password);
                data.append(
                    "new_password_confirmation",
                    this.new_password_confirmation
                );
                await this.$axios
                    // .post(`V1/reset-password`, data, {
                    //     headers: {
                    //         Authorization: "Bearer ".concat(
                    //             localStorage.getItem("access_token")
                    //         ),
                    //     },
                    // })
                    // .then((res) => {
                    //     this.token = res.data.token;
                    //     toaster.success(res.data.message);
                    //     this.liked_btn = 1;
                    //     (this.new_password = ""), (this.new_password_confirmation = ""), this.old_password = "";
                    //     this.v$.$reset()
                    // })
                    // .catch(() => {
                    //     toaster.error("Произошла ошибка! Повторите заново!");
                    // });
                    
                    .post(`V1/password-update`, data, {
                        headers: {
                            Authorization: "Bearer ".concat(
                                localStorage.getItem("access_token")
                            ),
                        },
                    })
                    .then((res) => {
                        this.token = res.data.token;
                        toaster.success(res.data.message);
                        this.liked_btn = 1;
                        (this.new_password = ""), (this.new_password_confirmation = ""), this.old_password = "";
                        this.v$.$reset()
                    })
                    .catch((err) => {
                        toaster.error("Произошла ошибка! Повторите заново!");
                    })
                }
        },
    },
    computed: {
        ...mapGetters(["getUser", "getType"]),
    },
};
</script>

<style lang="scss" scoped>
.allSITE {
    width: 100% !important;
    min-width: auto !important;
}
.button_auth {
    width: 35% !important;
}
.liked__button {
    &:hover {
        cursor: pointer;
    }
}

.acc-profile__avatar {
    margin-top: 32px;
    position: relative;
    width: 100px;
    height: 100px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 40px;
        height: 40px;
    }
    svg {
        position: absolute;
        right: 0;
        top: 0;
        &:hover {
            cursor: pointer;
            path {
                fill: #282828;
            }
            circle {
                fill: white;
            }
        }
    }
    @media screen and (max-width: 992px) {
      margin: 0 auto;
      margin-top: 32px;      
    }
}
.logoProf {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    border-radius: 50%;
}
.labelForImageProfile {
    input {
         display: none;
    }
}
.urForFiz {
    width: 75%;
}

.liked__button_active {
    background: rgba(5, 99, 14, 0.5);
    border-radius: 10px;
    color: white;
}
.acc-profile__input {
    input {
        border-radius: 10px;
    }
}
.button_auth {
    border-radius: 10px;
    font-weight: 400;
}
.authorization-enter__password {
    border-radius: 15px;
    overflow: hidden;
}
</style>
