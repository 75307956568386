<template>
  <div
    class="oneBackground"
    v-scroll
    v-if="false"
    @click="$store.state.buyOneClick = false, resetModalData()" 
  >
    <div class="container">
      <div class="oneClick">
        <img
          src="@/assets/icons/close.svg"
          class="closeIcon"
          @click="$store.state.buyOneClick = false, resetModalData()"
        />

        <div
          class="oneClick__content"
          @click.stop
          v-if="oneClickSended == false"
        >
          <p class="oneClick__content-title">{{ $t("Купитьводинклик") }}</p>
          <div class="oneClick__content__forms">
            <div class="oneClick__content__forms__box">
              <p class="oneClick__content__forms__box-p1">
                {{ $t("Имя") }} <span style="color: red">*</span>
              </p>
              <input type="text" v-model="v$.name.$model" @keyup="onlyText()" />
              <p v-if="v$.name.$error" class="errorData">Обьязательное поле</p>
            </div>

            <div class="oneClick__content__forms__box">
              <p class="oneClick__content__forms__box-p1">{{ $t("Город") }}</p>
              <input type="text" v-model="v$.city.$model" />
              <p v-if="v$.city.$error" class="errorData">Обьязательное поле</p>
            </div>

            <div class="oneClick__content__forms__box">
              <p class="oneClick__content__forms__box-p1">
                {{ $t("Телефон") }} <span style="color: red">*</span>
              </p>
              <input
                type="text"
                v-model="v$.number.$model"
                @input="isNumber"
                v-imask="phoneMask"
                @accept="onAccept"
                @complete="onComplete"
              />
              <p v-if="v$.number.$error" class="errorData">
                Введите номер формата +7(777)777 77 77
              </p>
            </div>
            <div class="oneClick__content__forms__box">
              <p class="oneClick__content__forms__box-p1">{{ $t("Адрес") }}</p>
              <input type="text" v-model="v$.address.$model" />
              <p v-if="v$.address.$error" class="errorData">
                Обьязательное поле
              </p>
            </div>
            <div class="oneClick__content__forms__box">
              <p class="oneClick__content__forms__box-p1">E-mail</p>
              <input type="text" v-model="v$.email.$model" />
              <p v-if="v$.email.$error" class="errorData">
                Введите корректный Email
              </p>
            </div>
            <div class="oneClick__content__forms__box" >
              <p class="oneClick__content__forms__box-p1">
                {{ $t("Введитекомментарий") }}
              </p>
              <input type="text" v-model="v$.comment.$model" />
              <p v-if="v$.comment.$error" class="errorData">
                Обьязательное поле
              </p>
            </div>
          </div>
          <div class="oneClick__content__confirm">
            <input type="checkbox" v-model="iagree" class="inputAgree"/>
            <p>{{ $t("Ясогласенспc") }}</p>
          </div>
          <button class="oneClick__content-button" @click="sendClick">
            {{ $t("Отправить") }}
          </button>
        </div>

        <div
          class="oneClick__confirm"
          @click.stop
          v-if="oneClickSended == true"
        >
          <p class="oneClick__confirm-title">{{ $t("Купитьводинклик") }}</p>
          <img src="@/assets/icons/success.png" />
          <p class="oneClick__confirm-p1">
            {{ $t("ВашOrderуспешнооформлен") }}
          </p>
          <p class="oneClick__confirm-p1">{{ $t("СпасибозаOrder") }}</p>
          <button @click="$store.state.buyOneClick = false">
            {{ $t("Закрыть") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
} from "@vuelidate/validators";
import { createToaster } from "@meforma/vue-toaster";
import { IMaskDirective } from "vue-imask";
import { log } from 'console';
const toaster = createToaster({ position: "top-right" });
export default {
  props: ["productId"],
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      v$: useVuelidate(),
    oneClickSended: false,
      iagree: false,
      name: "",
      city: "",
      number: "",
      address: "",
      email: "",
      comment: "",
      operators: [
        700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 747, 750, 751, 760,
        761, 762, 763, 764, 771, 775, 776, 777, 778,
      ],
      phoneMask: {
        mask: "+{7} ({000}) 000-00-00",
        lazy: true,
      },
    };
  },
  validations() {
    return {
      name: { required, minLength: minLength(2) },
      city: { required },
      number: { required, minLength: minLength(18) },
      address: { required },
      comment: {required},
      email: { required, email }
    };
    },
    watch: {
        'iagree'() {
            
        if (this.iagree) {
            document.querySelector('.inputAgree')?.classList?.remove('inputAgreeError')
        }
        }
    },
    methods: {
    

    resetModalData() {
      this.name = "",
      this.city = "",
      this.number = "",
      this.address = "",
      this.comment = "",
      this.email = "",
      this.iagree = false,
      this.v$.$reset()
    },
    
    async sendClick() {
    this.v$.$validate();
    
    if (!this.v$.$invalid && this.iagree) {
        const click = {
            product_id: this.productId,
            name: this.name,
            phone: this.number,
            email: this.email,
            city: this.city,
            address: this.address,
            comment: this.comment
        };

        await this.$axios
        .post("/V1/purchase", click, {
          headers: {
            lang: this.getLang,
          },
        })
        .then((response) => {
        if (response.status === 201) {
            this.oneClickSended = true;
            this.resetModalData();
        }
        })
        .catch((e) => {
            this.$toaster.error('Check the completed fields!')
        });
        
          }
    else {
        if (this.iagree) {
            document.querySelector('.inputAgree')?.classList?.remove('inputAgreeError')
        }
        else {
            document.querySelector('.inputAgree')?.classList?.add('inputAgreeError')
        }
        this.$toaster.error('Заполните обязательные поля!')
    }
    },

    onlyText() {
      this.name = this.name.replace(
        /[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s.]/gi,
        ""
      );
      this.name = this.name.replace(/\.{2,}|\s{2,}|\-{2,}/g, function (match) {
        return match.substr(0, 1);
      });
    },

    isNumber(e) {
      let regex = /[0-9]/;
      if (!regex.test(e.key)) {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      }
      if (e.target.value.slice(4, 7).length === 3) {
        if (this.operators.includes(parseInt(e.target.value.slice(4, 7)))) {
          // console.log("true", true);
        } else {
          e.target.value = " ";
          toaster.warning("Такого мобильного оператора не существует!");
        }
      }
    },
    onAccept(e) {
      const maskRef = e.detail;
      this.value = maskRef.value;
    },
    onComplete(e) {
      const maskRef = e.detail; // что бы в дальнейшем сохранить
      // console.log("complete", maskRef.unmaskedValue);
    },
  },
};
</script>
<style lang="scss" scoped>
.inputAgreeError {
    &::before {
        background: red;
        border: 2px solid red !important;
    }
}
.oneBackground {
  overflow: scroll;
  height: 100vh;
  color: black;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: #f9f9fd;
    cursor: pointer;
  }
}
.oneClick {
  border-radius: 10px;
  width: 52.38%;
  position: relative;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 32px 52px 32px;
  gap: 16px;
  margin: auto;
  @media (max-width: 992px) {
    width: 90%;
    padding: 12px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 992px) {
      width: 80%;
    }
    &-title {
      font-size: min(max(16px, calc(1rem + ((1vw - 3.2px) * 0.6923))), 25px);
      font-weight: 500;
      line-height: 46px;
      padding: 0 32px;
      margin-bottom: 12px;
    }
    &__forms {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 9.88%;
      row-gap: 16px;
      @media (max-width: 992px) {
        flex-direction: column;
        gap: 0;
        row-gap: 16px;
        width: 100%;
      }
      &__box {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 33.25%;
        color: #00000080;
        font-size: min(
          max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1538))),
          14px
        );
        @media (max-width: 992px) {
          width: 100%;
        }
        input {
          padding: 0 8px;
          font-size: min(
            max(10px, calc(0.625rem + ((1vw - 3.2px) * 0.1538))),
            12px
          );
          box-sizing: border-box;
          width: 100%;
          border: 1px solid rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          height: 40px;
        }
      }
    }
    &__confirm {
      font-size: min(
        max(10px, calc(0.625rem + ((1vw - 3.2px) * 0.1538))),
        12px
      );
      display: flex;
      align-items: center;
      color: #000000;
      font-weight: 500;
      gap: 10px;
      margin: 16px 0;
      input {
        &:before {
          content: "";
          position: absolute;
          top: -2px;
          left: 0px;
          // z-index: 1;
          width: 16px !important;
          height: 16px !important;
          border: 2px solid #05630E;
          background: white;
        }
        outline: none;
        accent-color: white;
        border: none;
        width: fit-content;
        transform: scale(1.1);
        &:checked ::after {
          content: url("@/assets/icons/checkboxBlack.svg");
          transform: translate(-50%, -50%);
          position: absolute;
          top: -2px;
          left: 0px;
          // z-index: 1;
          width: 16px !important;
          height: 16px !important;
          border: 2px solid black;
          background: white;
        }
      }
      input[type="checkbox"] {
        border: none;
      }
    }
    &-button {
      cursor: pointer;
      width: 44%;
      font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.2308))), 15px);
      height: 35px;
      background: #05630E;
      color: white;
      border-radius: 10px;
      transition: all 0.5s ease;
      &:hover {
        transform: scale(1.04);
      }
    }
  }
  &__confirm {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      font-size: min(max(16px, calc(1rem + ((1vw - 3.2px) * 0.6923))), 25px);
      font-weight: 500;
      line-height: 35px;
      padding: 0 12px;
      border-bottom: 2px solid black;
      margin-bottom: 23px;
    }
    img {
      width: 11.38%;
      margin-bottom: 10px;
    }
    &-p1 {
      font-size: min(max(16px, calc(1rem + ((1vw - 3.2px) * 0.6923))), 25px);
    }
    button {
      cursor: pointer;
      margin-top: 13px;
      font-size: min(max(16px, calc(1rem + ((1vw - 3.2px) * 0.6923))), 25px);
      width: 44%;
      background: #05630E;
      border-radius: 10px;
      color: white;
      height: 52px;
      transition: all 0.5s ease;
      &:hover {
        transform: scale(1.04);
      }
    }
  }
}

.closeIcon {
  cursor: pointer;
  width: auto;
  position: absolute;
  right: 16px;
  top: 16px;
  transition: all 0.7s ease;
  &:hover {
    rotate: 180deg;
    transform: scale(1.1);
  }
}
.errorData {
  color: red !important;
  font-size: min(
    max(10px, calc(0.625rem + ((1vw - 3.2px) * 0.1538))),
    12px
  ) !important;
}
</style>