<template>
  <div v-show="getLoading" class="mainload1">
    <div class="spinner"></div>
  </div>

  <div v-show="!getLoading" >
      <component :is="layout">
        <div class="allSITE">
            <router-view />
        </div>
      </component>
  </div>
</template>

<script>
import MainLayout from "@/views/layout/main";
import AuthLayout from "@/views/layout/auth";
import OrderLayout from "@/views/layout/order";
import AccLayout from "@/views/layout/acc";
import { mapGetters } from 'vuex';
export default {
  name: "App",
  components: { MainLayout, AuthLayout, OrderLayout, AccLayout },
  data: () => ({}),
  computed: {
    ...mapGetters(["getLoading", "getUser", 'getAuth']),
    layout() {
      return (this.$route.meta.layout || "main") + "-layout";
    },
  },
  watch: {
    $route() {      
      document.querySelector('.catalog-mobile')?.classList?.remove('catalog-mobile-animation')
      document.querySelector('.catalog-mobile')?.classList?.add('catalog-mobile-animation-hide')
        document.body.style.overflowY = 'auto'
        document.documentElement.style.overflowY = 'auto'
    }
  }
};
</script>



<style lang="scss">

.allSITE {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 700px;
}

#app {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #252525;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.overflowybody {
  overflow-y: hidden !important;
}





.mainload1 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.spinner {
 width: 56px;
 height: 56px;
 border: 11px #05630E double;
 border-left-style: solid;
 border-radius: 50%;
 animation: spinner-aib1d7 0.75s infinite linear;
}

@keyframes spinner-aib1d7 {
 to {
  transform: rotate(360deg);
 }
}

</style>

