<template>
    <div class="addshop">
         <div class="addshop__col1">
            <div class="addshop__col1__image">
                <img :src="require('@/assets/test/markets/' + shop.image + '.png')"/>
            </div>
            <div class="addshop__col1__text">
                <p class="addshop__col1__text-p1">{{ shop.title }}</p>
                <div class="addshop__col1__text-p2">
                    <div v-for="(item, index) in 5" :key="item">
                        <img src="@/assets/icons/startActive.png" v-if="index < shop.rating"/>
                        <img src="@/assets/icons/start.png" v-if="index >= shop.rating"/>
                    </div>
                </div>
                <p class="addshop__col1__text-p3">(25 отзывов)</p>
            </div>
         </div>

         <div class="addshop__col2">
            Minimum order - {{ shop.min_delivery }} £
         </div>

         <div class="addshop__col3">
            Delivery - tomorrow, May 24, free of charge Self-call - today
         </div>

         <div class="addshop__col4">
            5 600 £/th 
         </div>

         <button class="addshop__col5">
            Add to cart 
         </button>
         
         <div class="addshop__col6">
            5 600 £ 
         </div>
    </div>
    <div class="addshopLine" v-if="markets1?.length > 1 + index"></div>
</template>
<script>
export default {
    props: ['shop', 'index', 'markets1']
}
</script>
<style lang="scss" scoped>
.addshopLine {
    width: 95%;
    background: #E3E5E6;
    height: 1px;
}
.addshop {
    padding: 60px 0 35px 0;
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__col1 {
        display: flex;
        gap: 40px;
        align-items: center;
        &__image {
            height: 80px;
            width: 80px;
            background: red;
            border-radius: 50%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__text {
            &-p1 {

            }
            &-p2 {
                display: flex;
                align-items: center;
                margin: 5px 0;
            }
            &-p3 {

            }
        }
    }
    &__col2 {
        font-weight: 400;
        font-size: 16px;
    }
    &__col3 {
        font-weight: 400;
        font-size: 16px;
    }
    &__col4 {
        font-weight: 400;
        font-size: 16px;
    }
    &__col5 {
        background: #05630E;
        border-radius: 10px;
        padding: 12px 16px;
        font-weight: 400;
        color: white;
        font-size: 14px;
        border: 1px solid transparent;
        cursor: pointer;
        transition: all 0.5s ease;
        &:hover {
            background: white;
            color: black;
            border: 1px solid #05630E;
        }
    }
    &__col6 {
        font-weight: 400;
        font-size: 16px;
    }
}
</style>