<template>
  <div class="acc-order">
    <div class="acc-order__wrapper">
      <!-- <div class="acc__title" @click="$router.go(-1)" :class="getType === 'individual' ? 'indTitle' : ''">
      <img :src="require('@/assets/icons/leftArrow.png')" alt="left" />
        {{ $t("myPurchases") }}
      </div> -->
      <!-- {{ getOrderList }} -->
      <div class="acc-order__list">
        <select name="acc_order_list" id="" v-model="filter">
          <option value="all">All orders</option>
          <option value="countmore">By quantity (from less)</option>
          <option value="countless">By quantity (from most)</option>
          <option value="pricemore">By amount (from less)</option>
          <option value="priceless">By amount (from more)</option>
          <option value="statusmore">By status(paid)</option>
          <option value="statusless">By status(not paid)</option>
        </select>
      </div>
      <!-- <div class="acc-order__table">
                <div class="">

                </div> -->
      <!-- <div>
                <div class="acc-order__items">
                    <div class="acc-order__titles">
                        <div class="acc-order__title">
                            № Orderа
                        </div>
                        <div class="acc-order__title">
                            Дата Orderа
                        </div>
                        <div class="acc-order__title">
                            Наименование
                        </div>
                        <div class="acc-order__title">
                            Кол-во
                        </div>
                        <div class="acc-order__title">
                            Сумма
                        </div>
                        <div class="acc-order__title">
                            Статус
                        </div>
                        <div class="acc-order__empty">
                        </div>
                    </div>
                    <div class="acc-order__item" v-for="item in acc_order" :key="item.id">
                        <div class="acc-order__number">
                            {{ item.number }}
                        </div>
                        <div class="acc-order__date">
                            {{ item.date }}
                        </div>
                        <div class="acc-order__discription">
                            {{ item.discription }}
                        </div>
                        <div class="acc-order__count">
                            {{ item.count }} шт
                        </div>
                        <div class="acc-order__total">
                            {{ item.total }} Т
                        </div>
                        <div class="acc-order__status">
                            {{ item.status }}
                        </div>
                        <div class="acc-order__action">
                            {{ item.action }}
                        </div>
                    </div>
                </div>
            </div> -->
      <div class="acc-order__info">
        <table class="acc-order__table">
          <tr class="acc-order__table-header">
            <th>Product</th>
            <th>Order No.</th>
            <th>Order date</th>
            <th>Name</th>
            <th>Quantity</th>
            <th>Amount</th>
            <th>Payment status</th>
            <th>Order status</th>
            <th></th>
          </tr>
          <tr
            class="acc-order__table-content"
            v-for="(product, idx) in paginatedNews"
            :key="idx"
          >
            <router-link :to="'/product/' + product.slug"><td class="orderImg"><img :src="$cdn + product.main_image" alt="" /></td></router-link>
            <td>{{ product.id }}</td>
            <td>{{ product.date }}</td>
            <router-link :to="'/product/' + product.slug" style="color: black;"><td style="text-align: start; margin-left: 30px;">{{ product.product_name }}</td></router-link>
            <td style="width: 180px;padding-right: 0px;">{{ product.count }}{{ $t("pc") }}</td>
            <td>{{ product.total_price }}тг</td>

            <td
              class="acc-order__table-status"
            >
              <p v-if="product.pay_status > 0">Paid for</p>
              <p v-else-if="product.pay_status < 1">Not paid</p>
            </td>
            
            <td
              class="acc-order__table-status"
              :class="getClassForStatus(product.status)"
            >
              {{ product.status_name }}
            </td>
            <td class="acc-order__table-cancel">
              <span v-show="product.status != 4" @click="currentOrderId = product.id, $store.state.cancelOrder = true">Cancel</span>
            </td>
          </tr>          
        </table>
      </div>

      <div style="display: flex; justify-content: center"  class="awesCenter">
          <vue-awesome-paginate
            v-if="totalPages <= 7"
            :total-pages="totalPages"
            v-model="currentPage"
            :items-per-page="7"
            :total-items="(newMyOrders.length).toFixed(1)"
            :max-pages-shown="4"
            @page-change="pageChanged"
          />
        </div>

    </div>
  </div>
  <CancelOrder :orderId="currentOrderId"/>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CancelOrder from '@/components/CancelOrder.vue'

import "vue-awesome-paginate/dist/style.css";

export default {
  components: {CancelOrder},
  data() {
    return {
      currentPage: 1,
      isActivePage: 0,
      my_orders: [], // all orders list
      my_orders_showed: [], // rendered on page list
      pages: null,
      currentOrderId: null,
      nowActivePage: 1,
      numberOfPages: 10,
      filter: "all",
      newMyOrders: []
    };
  },
  async created() {
    this.order();
    this.fetchOrders("all")
  },
  watch: {
    filter(newValue) {
      this.fetchOrders(newValue)
    },
  },
  methods: {
    cancelOrder() {

    },
    pageChanged(page) {
      this.currentPage = page
    },

    fetchOrders(value) {
      this.$axios.get(`V1/orders${value.length && value !== 'all' ? '?sortby='+value : ''}`, {
        headers: {
            Authorization: "Bearer ".concat(
                localStorage.getItem("access_token")
            ),
        },
      }).then(res => {
          this.newMyOrders = res.data.data
      })
        .catch(err => {})
    },

    getClassForStatus(status) {
      switch (status) {
        case '1':
          return "acc-order__table-status-yellow";
        case '2':
          return "acc-order__table-status-green";
        case '3':
          return "acc-order__table-status-green";
        case '5':
          return "acc-order__table-status-yellow";
        case '6':
          return "acc-order__table-status-red";
        case '7':
          return "acc-order__table-status-yellow";
        case '8':
          return "acc-order__table-status-yellow";
      }
    },
    getStatus(num) {
      let allStatus = {
        0: "Completed",
        1: "At the pick-up point",
        2: "Delivered",
        3: "Awaiting payment",
        4: "Canceled",
      };
      if (num in allStatus) {
        return allStatus[num];
      } else {
        // console.log("num have no", num);
      }
    },
    clickedPage(i) {
      this.isActivePage = i;
      this.nowActivePage = i + 1;
      this.setShowedOrders();
      // console.log("now active page", this.nowActivePage);
    },
    order() {
      this.getOrders();
      this.setOrders();
      this.setPages();
    },
    async getOrders() {
      await this.GET_ORDERS_LIST();
    },
    setOrders() {
      this.my_orders = this.ACCOUNT_ORDERS;
    },
    setPages() {
      this.setShowedOrders();
      let page = this.my_orders.length / this.numberOfPages;
      this.pages = Math.ceil(page);
    },
    setShowedOrders() {
      let numberOfRenderedPages = this.numberOfPages * this.nowActivePage;
      let start = numberOfRenderedPages - this.numberOfPages;
      this.my_orders_showed = this.my_orders.slice(
        start,
        numberOfRenderedPages
      );
    },
    ...mapActions([
      "GET_ORDERS_LIST", // отправляю запрос для листа с Orderми
    ]),
  },
  computed: {
    ...mapGetters([
      "ACCOUNT_ORDERS",
      "getOrderList",
      "getType" // получаю запрос
    ]),

     paginatedNews() {
        const startIndex = (this.currentPage - 1) * 7;
        const endIndex = startIndex + 7;
        return this.newMyOrders.slice(startIndex, endIndex);
      },
      totalPages() {
        return Math.ceil(this.newMyOrders.length / 7);
      },

  },
};
</script>

<style lang="scss">
.orderImg {
  width: 100%;
  img {
    width: 50%;
  }
}
.acc-order__info {
  &::-webkit-scrollbar {
    cursor: pointer;
    width: 5px;
    height: 8px !important;
  }
}


.awesCenter {
  margin-top: 50px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
}
</style>