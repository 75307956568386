<template>
  <div>
    <LeaveModal v-if="isModal" @open-modal="isModal = false" :productId="productInfo.id"/>
    <div class="fb-block">
      <button class="fb-block__button"  v-if="getIsAuth" @click="isModal = true">
        {{ $t("leaveQuestions") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LeaveModal from "./LeaveModal.vue";
import StarsRatingVue from "./StarsRating.vue";
export default {
  data() {
    return {
      isModal: false,
      ordersTrue: false,
      feedbacks_items: [
        {
          id: 0,
          number: 5,
          count: 258,
          persernts: 15,
        },
        {
          id: 1,
          number: 4,
          count: 325,
          persernts: 20,
        },
        {
          id: 2,
          number: 3,
          count: 123,
          persernts: 35,
        },
        {
          id: 3,
          number: 2,
          count: 199,
          persernts: 20,
        },
        {
          id: 4,
          number: 1,
          count: 95,
          persernts: 10,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["ACCOUNT_ORDERS", "getOrderList", "getIsAuth"]),
    raitingWidth(id) {
      return (this.feedbacks_items[id].count / this.all_feedback) * 100;
      // return 22
    },
    // raitingWidthStyle(raitingWidth) {
    //     return `width: ${raitingWidth}%;`
    // }
    raitingWidthStyle(id) {
      return `width: ${this.raitingWidth(id)}%;`;
    },
  },
  components: {
    StarsRatingVue,
    LeaveModal
},
mounted() {
  // setTimeout(() => {
  //   this.ordersTrue = this.getOrderList.some(subArr => subArr.products.some(obj => obj.product_name === this.ids));    
  // }, 500);

  setTimeout(() => {
  const hasOrder = this.getOrderList && this.getOrderList.some(subArr => subArr.products && subArr.products.some(obj => obj.product_name === this.ids));
    this.ordersTrue = hasOrder;
  }, 500);
},
  props: {
    productInfo: {
      type: Array,
    },
    ids: {
      type: String,
    },
    rating: {
      type: Number,
      default: 0,
    },
    allFeedback: {
      type: Number,
      default: 1,
    },
    feedbacks: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  // props: [
  //     'rating', 'allFeedback', 'feedbacks'
  // ]
};
</script>

<style lang="scss" scoped>
.fb-block {
  padding-bottom: 30px;

  &__wrapper {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }

  &__rating {
    &-average {
      font-weight: 400;
      font-size: 44px;
      margin-bottom: 10px;
      @media (max-width: 576px) {
        font-size: 32px;
      }
    }

    &-all {
      margin-top: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #727272;
    }
  }

  &__line {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px;

    &-rating {
      position: relative;
      display: inline-flex;
      width: 230px;
      margin-bottom: 15px;
      @media (max-width: 576px) {
        width: 169px;
      }

      &-empty {
        position: absolute;
        width: 100%;
        height: 18px;
        left: 0px;
        top: 0px;
        background: #f7f7f7;
      }

      &-filled {
        position: absolute;
        height: 18px;
        left: 0px;
        top: 0px;
        background: #05630E;
      }
    }
  }

  &__button {
    margin-top: 20px;
    width: 222px;
    height: 52px;
    background: #05630E;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    color: #fff;
    transition: all 0.5s;

    &:hover {
      cursor: pointer;
      border: 1px solid #282828;
      background-color: #fff;
      color: #282828;
    }
  }
}
</style>