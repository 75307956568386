<template>
  <div class="authorization block animated fadeIn">
    <div class="content">
      <div class="authorization-enter">
        <div class="authorization-enter__wrapper">
          
          <h2 class="authorization__title">{{ $t("Регистрация") }}</h2>
          
          <div class="authorization-enter__items">

            <div class="authorization-enter__item">
              <p class="authorization-enter__name">
                {{ $t('Name') }} <span>*</span>
              </p>
              <div class="authorization-enter__input">
                <input type="text" v-model="v$.name.$model" @keyup.enter="submitForm"/>
                <span v-if="v$.name.$error">
                  Имя должно быть больше чем 2 символа
                </span>
              </div>
            </div>

            
            <div class="authorization-enter__item">
              <div class="authorization-enter__name">
                {{ $t('Surname') }} <span>*</span>
              </div>
              <div class="authorization-enter__input">
                <input type="text" v-model="v$.fio.$model" id="fio-input" @keyup.enter="submitForm"/>
                <span v-if="v$.fio.$error">Введите корректный ФИО</span>
              </div>
            </div>

            <div class="authorization-enter__item">
              <div class="authorization-enter__name">
                {{ $t('E-mail') }} <span>*</span>
              </div>
              <div class="authorization-enter__input">
                <input type="email" v-model="v$.email.$model" @keyup.enter="submitForm"/>
                <span v-if="v$.email.$error">Введите корректный Email</span>
              </div>
            </div>


            <div class="authorization-enter__item">
              <div class="authorization-enter__name">
                {{ $t('Phone number') }} <span>*</span>
              </div>
              <div class="authorization-enter__input">
                <input
                  @keyup.enter="submitForm"
                  type="text"
                  v-model="v$.number.$model"
                  @input="isNumber"
                  v-imask="phoneMask"
                  @accept="onAccept"
                  @complete="onComplete"
                />
                <span v-if="v$.number.$error"
                  >Введите номер формата +7(777)777 77 77</span
                >
              </div>
            </div>

            <div class="authorization-enter__item">
              <div class="authorization-enter__name">{{ $t('Пароль') }}<span>*</span></div>
              <div
                class="authorization-enter__input authorization-enter__password"
              >
                <div class="">
                  <input
                    @keyup.enter="submitForm"
                    :type="showPass ? 'password' : 'text'"
                    v-model="v$.password.password.$model"
                    id="password-input"
                    readonly
                    onfocus="this.removeAttribute('readonly');"
                    name="password"
                  />
                  <span class="eye" @click="showPass = !showPass">
                    <img
                      :src="
                        require(`@/assets/icons/${
                          !showPass ? 'eyeshow' : 'eyehide'
                        }.png`)
                      "
                    />
                  </span>
                </div>
              </div>
              <template
                v-for="(error, index) of v$.password.password.$errors"
                :key="index"
              >
                <span>Пароль должен состоят как минимум из 6 символов</span>
              </template>
            </div>

            <div class="authorization-enter__item">
              <div class="authorization-enter__name">
                {{ $t('Повторить пароль') }}<span>*</span>
              </div>
              <div
                class="authorization-enter__input authorization-enter__password"
              >
                <div class="">
                  <input
                    @keyup.enter="submitForm"
                    :type="showPass1 ? 'password' : 'text'"
                    v-model="v$.password.confirm.$model"
                    readonly
                    onfocus="this.removeAttribute('readonly');"
                    id="password-input1"
                    name="password1"
                  />
                  <span class="eye" @click="showPass1 = !showPass1">
                    <img
                      :src="
                        require(`@/assets/icons/${
                          !showPass1 ? 'eyeshow' : 'eyehide'
                        }.png`)
                      "
                    />
                  </span>
                </div>
              </div>
              <template
                v-for="(error, index) of v$.password.confirm.$errors"
                :key="index"
              >
                <span>Пароли не совпадают</span><br />
              </template>
            </div>

            <!-- <div class="authorization-enter__item">
              <div class="authorization-enter__name">
                {{ $t('Company name') }} <span>*</span>
              </div>
              <div class="authorization-enter__input">
                <input type="text" v-model="v$.information.$model" />
              </div>
            </div> -->

          </div>

          <div class="authorization-enter__activity">
            <div class="authorization-enter__active">
              <label class="input_checkbox"
                ><p class="authorization-enter__personaldata">
                  Я согласен на обработку
                  <router-link to="/personal-data" target="_blank"
                    >персональных данных</router-link
                  >
                  и ознакомился с
                  <router-link to="/confidential" target="_blank"
                    >политикой конфиденциальности</router-link
                  >.
                </p>
                <input type="checkbox" id="checkbox" v-model="agreed" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>

          <button
            class="button button_auth"
            @click="submitForm"
          >
            {{ $t("register") }}
          </button>

          <div class="authorization-enter__registration">
            {{ $t("haveAccount") }}
            <span @click="$router.push('/login')">{{ $t("comeIn") }}</span>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { directive } from '@babel/types'
import useVuelidate from "@vuelidate/core";
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
} from "@vuelidate/validators";
import { IMaskDirective } from "vue-imask";
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import { mapGetters, mapMutations } from 'vuex';
const toaster = createToaster({ position: "top-right" });
export default {
  data() {
    return {
      v$: useVuelidate(),
      name: "",
      fio: "",
      cities: [],
      city: null,
      bin: "",
      iin: "",
      email: "",
      number: "",
      operators: [
        700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 747, 750, 751, 760,
        761, 762, 763, 764, 771, 775, 776, 777, 778,
      ],
      showPass: true,
      typeRegister: "physical",
      showPass1: true,
      address: {
        legal: "",
        actual: "",
      },
      password: {
        password: "",
        confirm: "",
      },
      information: "",
      agreed: false,
      phoneMask: {
        mask: "+{7} ({000}) 000-00-00",
        lazy: true,
      },
      binMask: {
        mask: "############",
        lazy: true,
      },
    };
  },
  props: ["hide"],
  computed: {
    ...mapGetters(["getLang"]),
    formValid() {
      return this.v$.$invalid;
    },
  },
  validations() {
    // const mustBeCorrectPhoneNumber = (value) => value.include('+7')
    return {
      name: { required, minLength: minLength(2) },
      fio: { required, minLength: minLength(2) },
      bin: { required, minLength: minLength(12), maxLength: maxLength(12) },
      iin: { required, minLength: minLength(12) },
      email: { required, email },
      number: { required },
      address: {
        legal: { required },
        actual: { required },
      },
      password: {
        password: { required, minLength: minLength(6) },
        confirm: { required, sameAs: sameAs(this.password.password) },
      },
      information: "",
      agreed: { required },
    };
  },
  methods: {
    ...mapMutations(["SET_TYPE"]),
    isNumber(e) {
      let regex = /[0-9]/;
      if (!regex.test(e.key)) {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      }
      if (e.target.value.slice(4, 7).length === 3) {
        if (this.operators.includes(parseInt(e.target.value.slice(4, 7)))) {
          // console.log("true", true);
        } else {
          e.target.value = " ";
          toaster.warning("Такого мобильного оператора не существует!");
        }
      }
    },
    onAccept(e) {
      const maskRef = e.detail;
      this.value = maskRef.value;
    },
    onComplete(e) {
      const maskRef = e.detail; 
    },
    checkPassword(password) {
      if (password) {
      }
    },
    checkPhoneNumber(value) {
      return value.include("+7");
    },
    submitForm() {
      this.v$.$validate();
      if (
        !this.v$.name.$invalid &&
        !this.v$.fio.$invalid &&
        !this.v$.email.$invalid &&
        !this.v$.password.password.$invalid &&
        !this.v$.password.confirm.$invalid &&
        !this.v$.information.$invalid
      ) {
        const article = {
          phone: this.number,
          name: this.name,
          email: this.email,
          password: this.password.password,
          password_confirmation: this.password.confirm,
          type: this.typeRegister
        };
        axios
          .post("/V1/register", article, {
            headers: {
              lang: this.getLang
            }
          })
          .then((response) => {
            if (response.status === 201) {
              localStorage.setItem("access_token", response.data.access_token);
              localStorage.setItem("type", response.data.data.type);
              this.SET_TYPE(response.data.data.type)
              this.$router.push("/acc/profile");
              location.reload();
              toaster.success("Вы успешно прошли регистрацию!");
            }
            if (response.status === 400) {
              console.log("Вы уже зареганны");
            } else {
              console.log(response);
            }
          })
          .catch((e, response) => {
            toaster.error(
              e.response.data?.errors[Object.keys(e.response.data?.errors)[0]]
            );
          });
      }
      else {
              toaster.error("Fill in the required fields");
      }
    },
  },
  directives: {
    imask: IMaskDirective,
  },
  watch: {
    typeRegister() {
      this.v$.$reset();
      (this.address = {
        legal: "",
        actual: "",
      }),
        (this.password = {
          password: "",
          confirm: "",
        }),
        (this.information = ""),
        (this.name = ""),
        (this.fio = ""),
        (this.bin = ""),
        (this.iin = ""),
        (this.email = ""),
        (this.number = "");
    },
  },
};
</script>

<style lang="scss" scoped>
.authorization-enter {
  border-radius: 8px;
  
    &::-webkit-scrollbar {
      width: 3px;
      height: 10%;
      border-radius: 50%;
      background-color: #000000;
    }

    &::-webkit-scrollbar-thumb {
        // border-radius: 10px;
        background-color: #0c0000;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background-color: #f9f9fd;
    }
}
.typeRegister-item {
  input {
    accent-color: black;
  }
  label {
         font-size: min(max(10px, calc(0.625rem + ((1vw - 4px) * 0.3279))), 14px) !important;
  }
}
.typeRegister {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: space-between;
  &-item {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  @media (max-width: 991px) {
    gap: 5px;
    width: 100%;
    justify-content: space-between;
    &-item {
      gap: 1px;
      font-size: 14px;
    }
  }
}

select {
  margin: 5px 0 0 0;
  width: 100%;
  padding: 20px 12px;
  border: 1px solid #C2C2C2;
  outline: none;
}



.animated {
  animation-duration: 1s; /* длительность анимации */
  animation-fill-mode: both; /* сохраняем состояние после анимации */
}
.fadeIn {
  animation-name: fadeIn; /* имя анимации */
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.authorization-enter {
  position: relative;
}
.closeIcon {
  cursor: pointer;
  width: auto;
  position: absolute;
  right: 16px;
  top: 16px;
  transition: all 0.7s ease;
  &:hover {
    rotate: 180deg;
    transform: scale(1.1);
  }
}
</style>