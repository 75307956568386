<template>
  <div class="card" v-if="productData" :class="{cardBasketStyle: isCardInBasket == true}">
    <router-link 
      :to="'/product/' + productData.slug">

    <div
      class="card__like"
      @click.prevent="getIsAuth ? pressLikeIcon(productData.id) : notWishlist()"
      :class="{ liked: isLiked === true && getIsAuth}"
    ></div>
    <div class="card__blockLeft"></div>
    <div class="card__blockRight" v-if="isCardInBasket == true"></div>
    <div class="card__sale" v-if="productData.sale">
      {{ productData.sale }} %
    </div>
    <div class="card__sale" v-else-if="typeSale && Math.trunc(100 - (productData.current_price / productData.price)*100) !== 0">
      {{ Math.round(100 - (productData.current_price / productData.price)*100) }} %
    </div>
    <router-link
      :to="'/product/' + productData.slug"
      class="card__image"
      @click.prevent="sendSingleProduct(productData.id, productData.slug)"
    >
      <!-- <img :src="$cdn + productData.main_image" alt="Image" /> -->
      <v-lazy-image :src="$cdn + productData.main_image" alt="product"/>
    </router-link>
    <div class="card__info">
      <div
        class="card__discr"
        @click="sendSingleProduct(productData.id, productData.slug)"
      >
        <span><img src="@/assets/icons/cardKey.svg"/></span><p v-html="productData.title"></p>
      </div>
      <div class="card__price">
        <span><img src="@/assets/icons/cardKey.svg"/></span>
        <div class="card__price-now">
          {{ productData.current_price }}£/{{ $t("pc") }}
        </div>
        <div
          class="card__price-sale"
          v-if="productData.current_price !== productData.price"
        >
          {{ productData.price }}£
        </div>
      </div>
      <button
        class="card__button"
        @click.prevent="addBasketCard(productData)"
        :class="{ 'card__button-delete': isCardInBasket !== false }"
      >
        {{ basket_status }}
      </button>
    </div></router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
const toaster = createToaster({ position: "top-right" });
import VLazyImage from "v-lazy-image";
import { createToaster } from "@meforma/vue-toaster";
export default {
  components: {
    VLazyImage
  },
  data() {
    return {
      basket: null,
      basket_status: this.$t("addCart"),
      isCardInBasket: true,
      isLiked: null,
      favourId: null,
      productData: this.product,
    };
  },
  props: {
    product: {
      type: Object
    },
    typeSale: {
      type: Boolean,
      default: false
    },    
    likes: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
      this.isLiked = this.getLiked.find((i) =>
        i.product_id == this.productData.id 
      );
      // console.log('assad', this.isLiked);
      if (this.isLiked || this.likes) {
        this.isLiked = true
      } else {
        this.isLiked = false
    } 
      this.$router.fullPath === '/likeds' || this.$router.fullPath === '/acc/liked' ? this.isLiked = false : ''
    this.isCardInBasket = this.$store.state.card.some((i) =>
      i.id === this.productData.id ? true : false
    );
    this.basket_status = !this.isCardInBasket
      ? this.$t("addCart")
      : this.$t("deleteCart");
    this.typeSale ? this.productData.sale = Math.round(100 - (this.productData.current_price / this.productData.price) * 100) : ''
  },
  computed: {
    ...mapGetters([
      "getIsAuth", "getLiked"
    ]),
  },
  watch: {
    getLiked() {
      this.isLiked = this.getLiked.find((i) =>
        i.product_id == this.productData.id
      );
      // console.log('assad', this.isLiked);
      if (this.isLiked || this.likes) {
        this.isLiked = true
      } else {
        this.isLiked = false
      } 
      this.$router.fullPath === '/likeds' || this.$router.fullPath === '/acc/liked' ? this.isLiked = false : ''
    }
  },
  methods: {
    ...mapActions([
      "ADD_PRODUCT_TO_BASKET",
      "ADD_LIKED_PRODUCT",
      "DELETE_CARD_ITEM",
      "GET_ALL_PRODUCTS_INFO",
      "DELETE_BASKET_ITEM",
    ]),
    ...mapMutations(["SET_ALL_FAVOURITES"]),
    notWishlist() {
      toaster.error(this.$t('notAddWishlist'));
    },
    async sendSingleProduct(id, sendSingleProduct) {
      await this.$store.commit("SET_SHOP_CARD", this.productData);
      await this.GET_ALL_PRODUCTS_INFO();

      // this.linkTo(sendSingleProduct);
    },
    getSingleProduct(id) {
      let arr = this.$store.state.allProductInfo;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id == id) {
          this.$store.commit("SET_SINGLE_PRODUCT_INFO", arr[i]);
          // console.log(
          //   "this.$store.state.singleProductInfo",
          //   this.$store.state.singleProductInfo
          // );
        } else {
          return;
        }
      }
    },
    checkCardStatus() {
      if (this.isCardInBasket == true) {
        this.basket_status = this.$t("addCart");
        this.DELETE_BASKET_ITEM(this.productData.id);
        this.$emit("deleteBasketCard", this.productData.id);
      }
        else {
        this.basket_status = this.$t("deleteCart");
        this.$emit("addBasketCard", this.productData);
        this.ADD_PRODUCT_TO_BASKET(this.productData);
      }
      this.isCardInBasket != this.isCardInBasket;
    },
    addBasketCard(id) {
      this.checkCardStatus(id);
      this.isCardInBasket = !this.isCardInBasket;
    },
    async pressLikeIcon(id) {
      // this.isLiked = !this.isLiked;
      // this.productData.quanitity = 1;
      // if (this.isLiked == true) {
      //   console.log("!!product data", this.productData);
      //   this.$emit("addLikeCard", this.productData);
      //   this.ADD_LIKED_PRODUCT(this.productData);
      // } else {
      //   this.$emit("deleteLikeCard", this.productData.id);
      //   this.DELETE_CARD_ITEM(this.productData.id);
      // }
      if (this.isLiked === false) {
        this.$axios.post('V1/add-favourite?lang=ru', {
          product_id: id
        }, {
            headers: {
                Authorization: "Bearer ".concat(
                    localStorage.getItem("access_token")
                ),
            },
        })
        .then(res => {
          this.ADD_LIKED_PRODUCT(res.data.data);
          this.productData.favourId = res.data.data.id
          this.isLiked = true
          
      this.$axios.get('V1/get-favourites', {
          headers: {
              Authorization: "Bearer ".concat(
                  localStorage.getItem("access_token")
              ),
          },
        })
        .then(res => {
          this.SET_ALL_FAVOURITES(res.data.data)
        })
        // .catch(err => console.log(err))
        })
        // .catch(err => console.log(err))
      } else {
        this.$axios.delete('V1/delete-favourite?lang=ru', {
          params: {
            product_id: this.likes ? parseInt(this.productData.product_id) : this.productData.id 
          },
          headers: {
              Authorization: "Bearer ".concat(
                  localStorage.getItem("access_token")
              ),
          },
        })
        .then(res => {
          this.DELETE_CARD_ITEM(this.likes ? parseInt(this.productData.product_id) : this.productData.id);
        })
        // .catch(err => console.log(err))
      }
    },
    linkTo(id) {
      this.$router.push("/product/" + id);
    },
  },
};
</script>

<style lang="scss">
.liked {
  background-image: url("@/assets/icons/card/like_filled.svg") !important;
}
.card__blockLeft {
  transition: all 0.3s ease;
  width: 10%;
  height: 30%;
  background: white;
  rotate: 45deg;
  top: -10%;
  left: -5%;
  position: absolute;
}
.card__blockRight {
  transition: all 0.3s ease;
  width: 10%;
  height: 30%;
  background: #05630E;
  rotate: -45deg;
  top: -10%;
  right: -5%;
  position: absolute;
}

.cardBasketStyle {
  &:hover .card__blockLeft {
    background: transparent !important;
  }
}
.card {
  overflow: hidden;
  margin: 10px 0;
  position: relative;
  padding-top: 13px;
  width: 100%;
  border-radius: 15px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;

  &:hover {
    transform: scale(1.1);
  }
  &:hover .card__info {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &:hover .card__blockLeft {
    background: #05630E;
  }
  &:hover .card__like {
    filter: brightness(0) saturate(100%)
  }
  &:hover .card__discr {
    filter: brightness(0) saturate(100%)
  }
  &:hover .card__price {
    filter: brightness(0) saturate(100%)
  }
  &:hover .card__button {
    background: #05630E;
    color: white;
  }

  &__like {
    position: absolute;
    z-index: 3;
    bottom: 7%;
    right: 7%;
    height: 16px;
    width: 16px;
    background-image: url("@/assets/icons/card/like.svg");
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(204deg) brightness(104%) contrast(104%);
    @media screen and (max-width: 992px) {
        bottom: 7%;
        right: 7%;
    }
    @media screen and (max-width: 992px) {
      top: auto !important;
        bottom: 6% !important;
        right: 7% !important;
    }
  }

  &__sale {
    position: absolute;
    width: 150px;
    height: 29px;
    right: -31px;
    z-index: 1;
    rotate: 320deg;
    top: 49%;
    background-color: #ed3737;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1300.98px) {
      top: 45%;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 42px 0;
    @media (max-width: 576.98px) {
      max-width: 100%;
    }
    img {
      width: 62.64%;
      object-fit: contain;
      max-height: 160px;
      height: 160px;
    }
  }

  &__info {
    background: #05630E;
border-radius: 15px;
    border: 1px solid transparent;
    position: relative;
    z-index: 2;
    padding: 3% 8.7%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: all 0.3s ease;
  }

  &__discr {
    height: 46px;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: white;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    img {
      width: auto;
    }
  }

  &__price {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 11px;

    &-now {
      font-weight: 600;
      font-size: min(max(12px, calc(0.75rem + ((1vw - 12px) * 0.8333))), 18px) !important;
      color: white;
    }

    &-sale {
      font-weight: 400;
      font-size: min(max(11px, calc(0.6875rem + ((1vw - 12px) * 0.6944))), 16px) !important;
      text-decoration-line: line-through;
      color: #727272;
    }
  }

  &__button {
    width: fit-content;
    padding: 0 19%;
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: min(max(35px, calc(2.1875rem + ((1vw - 3.2px) * 0.625))), 45px);
    font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1538))), 14px);
    margin-right: auto;
    margin-left: auto;
    color: black;
    background: white;
    font-weight: 500;
    text-align: center;
    transition: all 0.5s;
    @media (max-width: 1300px) {
      padding: 0 10%;
    }
    @media (max-width: 1050px) {
      padding: 0 4%;
    }
    @media (max-width: 991px) {
      padding: 0 3%;
    }
    &:hover {
      cursor: pointer;
    }

    &-delete {
      background: #fff;
      border: 1px solid #282828;
      color: #282828;
    }
  }
}

@media (max-width: 576.98px) {
  .card {
    position: relative;
    // padding: 24px 8px;
    width: 100%;
    max-height: auto;

    &__like {
      position: absolute;
      top: 8px;
      right: 8px;
    }

    &__sale {
      position: absolute;
      height: 25px;
      right: -45px;
      bottom: 70px;
      top: 120px;
      font-size: 12px;
    }

    &__image {
      margin: 0 auto;
      width: 100%;
      min-height: 130px;
      max-height: 130px;

      img {
        object-fit: contain;
        max-height: 130px;
      }
    }

    &__info {
      margin-top: 10px;
      padding: 3px 0;
    }

    &__discr {
      font-weight: 400;
        font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.3846))), 16px);
      line-height: 21px;
      margin-top: 10px;
    }

    &__price {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 5px;

      &-now {
        font-size: 13px;
      }

      &-sale {
        font-size: 13px;
      }
    }

    // &__button {
    //   margin-top: 12px;
    //   padding: 12px 16px;
    //   // width: 100%;
    //   height: 36px;
    //   margin-bottom: 0px;

    //   font-weight: 500;
    //   font-size: 13px;
    // }
  }
}

.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}

.v-lazy-image-loaded {
  filter: blur(0);
}
</style>