<template>
    <div class="application">
        <div class="content">
            <div class="auth-header__steps">
            <div class="auth-header__icon">
                <img src="../../assets/icons/auth/registration_end.svg" alt="" />
            </div>
            <div class="auth-header__items">
                <div class="auth-header__item">1. Authorization</div>
                <div class="auth-header__item">2. Making an order</div>
                <div class="auth-header__item">3. Sending an application</div>
            </div>
            </div>
            <div class="application__wrapper">
                <div class="application__background">
                    <img src="../../assets/img/application2.png" alt="">
                </div>
                <p class="yourRevSended">Your application has been sent!</p>
                <div class="application__text">
                    Your application has been successfully submitted and will be processed by the manager in the near future
                </div>
                <!-- <a href="/payment.docx" download="" class="application__bill" v-if="getType !== 'physical'">
                    <div class="application__doc">
                        <img src="../../assets/icons/auth/doc.svg" alt="">
                    </div>
                    Скачать счет на оплату
                </a> -->
                <button class="button button_application" @click="$router.push('/')">
                    <div class="application__back">
                        <img src="../../assets/icons/auth/arrow_back_white.svg" alt="">

                    </div>
                    Go back to the main page
                </button>
            </div>

        </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(["getType"])
    }
}
</script>

<style lang="scss" scoped>
.application {
    padding: 20px 0 60px 0;
}
.yourRevSended {
    margin-top: 32px;
    font-size: min(max(22px, calc(1.375rem + ((1vw - 3.2px) * 0.625))), 32px);
    font-weight: 600;
}
.application__wrapper {
    margin-top: 60px;
}

.auth-header {
  height: 100px;
  background: #ffffff;
  padding: 20px 0 0 0;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    width: 80px;

    span {
      @media (max-width: 991px) {
        display: none;
      }
    }
  }

  &__steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px 0;

    @media (max-width: 991px) {
      display: none;
    }
  }

  &__icon {
    width: 577px;
  }

  &__items {
    display: flex;
    gap: 0 118px;
    align-items: center;

    font-size: 16px;
    font-weight: 600;
    color: #202020;
  }

  &__item {
    &:nth-child(3) {
      font-weight: 500;
      color: #c2c2c2;
    }
  }
}
</style>