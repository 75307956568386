<template>
    <div class="header-modal leQ">
        <div class="header-modal__bg" @click.self="$emit('openModal')" v-scroll>
            <div class="modal__take">
                <div class="modal__take-close">
                    <div class="modal__take-icon" @click="$emit('openModal')">
                        <img src="../assets/icons/components_icon/close.svg" alt="">
                    </div>
                </div>
                
                <div class="okk" v-if="leqOpen">
                  <div class="okk-p1">
                    Ваш вопрос успешно отправлен!
                  </div>
                  <button class="okk-button" @click.self="$emit('openModal')">
                    Закрыть
                  </button>
                </div>

                <div class="modal__take-wrapper" v-else-if="!leqOpen">
                    <div class="modal__take-title">
                        <p>Задать вопрос</p>
                    </div>
                    <p class="leQ-p1">Напишите ваш вопрос</p>
                    <p class="leQ-p2">Вам ответит продавец, представитель бренда, купивший этот товар. Пришлем уведомление, когда поступит ответ</p>
                    <textarea class="leQ-textarea" v-model="v$.comment.$model"></textarea>
                    <p v-if="v$.comment.$error" class="errorData">
                      Комментарий должен состоять минимум из 10 слов
                    </p>
                    <div class="leQ__bottom">
                        <div class="leQ__bottom__left">
                            <p>Вы оставляете комментарий как: <span>{{ userName }}</span></p>
                            <div class="leQ__bottom__left-gap">
                                <p>Оставить вопрос анонимно </p>
                                <label class="checkbox-google">
                                    <input type="checkbox" v-model="inputValue"/>
                                    <span class="checkbox-google-switch"></span>
                                </label>
                            </div>
                        </div>
                        <button class="leQ__bottom-button" @click="clickOnSend()">Отправить вопрос</button>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>
<script>
import { mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
} from "@vuelidate/validators";
export default {
    props: ['productId'],
    data() {
    return {
        v$: useVuelidate(),
        modal: false,
        leqOpen: false,
        inputValue: false,
        comment: "",
        userName: ""
        }
    },
    validations() {
      return {
          comment: { required, minLength: minLength(10) },
        }
    },
    watch: {
      inputValue() {
        this.inputValueMethod();
      }
  },
  mounted() {
       this.inputValueMethod();  
    },
  methods: {
    inputValueMethod() {
        if (!this.inputValue) {
          if (this.getUser.lname) {
            this.userName = this.getUser.name + ' ' + this.getUser.lname.charAt(0) + '.';
          } else {
            this.userName = this.getUser.name;
          }
        }
        else {
          this.userName = "Анонимный пользователь"
        }
      },
        openModal() {
            this.$emit('openModal')
      },
      async clickOnSend() {
        this.v$.$validate();
        if (!this.v$.$invalid) {
          const data = {
            name: this.userName,
            question: this.comment,
            product_id: this.productId
          }

        await this.$axios
        .post("/V1/question/create", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            lang: this.getLang,
          },
        })
        .then((response) => {
          this.$toaster.success('Успешно!')
          // console.log('sadsd');
          this.leqOpen = true;
        })
        .catch((e) => {
            this.$toaster.error('Check the completed fields!')
        });
        
          }
    else {
        if (this.iagree) {
            document.querySelector('.inputAgree')?.classList?.remove('inputAgreeError')
        }
        else {
            document.querySelector('.inputAgree')?.classList?.add('inputAgreeError')
        }
        this.$toaster.error('Заполните обязательные поля!')
    }
        }
    },
    computed: {
        ...mapGetters(["getUser"]),
    },
}
</script>

<style lang="scss" scoped>
.okk {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 45px;
  justify-content: center;
  align-items: center;
  &-p1 {
      font-size: min(max(16px, calc(1rem + ((1vw - 3.2px) * 0.6923))), 25px);
  }
  &-button {
    cursor: pointer;
    width: 52%;
    font-size: min(max(16px, calc(1rem + ((1vw - 3.2px) * 0.6923))), 25px);
    color: white;
    background: #000000;
    height: 55px;
    border: 1px solid transparent;
    transition: all 0.5s ease;
    opacity: 0.8;
    &:hover {
      border: 1px solid black;
      opacity: 1;
    }
  }
}
.leQ {
    &-p1 {
        font-weight: 500;
        margin-top: 27px;
        margin-bottom: 5px;
        text-align: start;
          font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.3077))), 16px);
          color: #282828;
    }
    &-p2 {
        margin-bottom: 20px;
          font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.3077))), 16px);
          color: #727272;
    }
    &-textarea {
        border: 1px solid #C2C2C2;
        padding: 12px;
        box-sizing: border-box;
        height: 143px !important;
    }
    &__bottom {
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
        &__left {
          font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.3077))), 16px);
            &-gap {
                margin-top: 15px;
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }
        &-button {
            cursor: pointer;
            padding: 0 37px;
            background: #05630E;
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
            height: 52px;
          font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.3077))), 16px);
            color: white;
        }
    }
}
.header {
    &-modal {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0;
        z-index: 99999999;
        display: flex;
        align-items: center;
        justify-content: center;

        &__bg {
            position: fixed;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, 0.36);
            z-index: -1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.modal__take {
    padding: 16px 16px 80px;
    width: 800px;
    height: fit-content;
    background: #FFFFFF;

    &-close {
        display: flex;
        justify-content: flex-end;
    }

    &-wrapper {
        padding: 0 39px;
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: space-between;
        @media (max-width: 991px) {
            padding: 10px;
        }
    }

    &-icon {
        width: 24px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &-title {
        margin-top: 40px;
        font-weight: 600;
        font-size: 32px;
        color: #202020;
        text-align: center;
    }

    &-subtitle {
        margin-top: 32px;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #727272;
    }

}


.checkbox-google {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  margin-right: 10px;
  position: relative;
  vertical-align: middle;
  font-size: 14px;
  user-select: none;
}

.checkbox-google .checkbox-google-switch {
  display: inline-block;
  width: 36px;
  height: 14px;
  border-radius: 20px;
  position: relative;
  top: 6px;
  vertical-align: top;
  background: #9f9f9f;
  transition: 0.2s;
}

.checkbox-google .checkbox-google-switch:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -3px;
  left: -1px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: 0.15s;
}

.checkbox-google input[type="checkbox"] {
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox-google input[type="checkbox"]:checked + .checkbox-google-switch {
  background: #05630E;
}

.checkbox-google
  input[type="checkbox"]:checked
  + .checkbox-google-switch:before {
  background: #e3e5e6;
  transform: translateX(18px);
}

/* Hover */
.checkbox-google
  input[type="checkbox"]:not(:disabled)
  + .checkbox-google-switch {
  cursor: pointer;
  border-color: rgba(0, 0, 0, 0.3);
}

/* Active/Focus */
.checkbox-google
  input[type="checkbox"]:not(:disabled):active
  + .checkbox-google-switch:before,
.checkbox-google
  input[type="checkbox"]:not(:disabled):focus
  + .checkbox-google-switch:before {
  animation: checkbox-active-on 0.5s forwards linear;
}

@keyframes checkbox-active-on {
  0% {
    box-shadow: 0 0 0 0 rgba(212, 212, 212, 0);
  }

  99% {
    box-shadow: 0 0 0 10px rgba(212, 212, 212, 0.5);
  }
}

.checkbox-google
  input[type="checkbox"]:not(:disabled):checked:active
  + .checkbox-google-switch:before,
.checkbox-google
  input[type="checkbox"]:not(:disabled):checked:focus
  + .checkbox-google-switch:before {
  animation: checkbox-active-off 0.5s forwards linear;
}

@keyframes checkbox-active-off {
  0% {
    box-shadow: 0 0 0 0 rgba(154, 190, 247, 0);
  }

  99% {
    box-shadow: 0 0 0 10px rgba(154, 190, 247, 0.5);
  }
}

/* Disabled */
.checkbox-google input[type="checkbox"]:disabled + .checkbox-google-switch {
  filter: grayscale(60%);
  border-color: rgba(0, 0, 0, 0.1);
}

.checkbox-google
  input[type="checkbox"]:disabled
  + .checkbox-google-switch:before {
  background: #eee;
}

.errorData {
  margin-top: 8px;
  color: red !important;
  font-size: min(
    max(10px, calc(0.625rem + ((1vw - 3.2px) * 0.1538))),
    12px
  ) !important;
}
</style>
