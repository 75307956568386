import { createStore } from "vuex";

import axios from "axios";
// import createPersistedState from "vuex-persistedstate";
// import api from '../api/index';
import productData from "../data/product_list.json";
import orders from "../data/account_orders.json";
import feedbacks from "../data/feedbacks.json";
import productAllInfo from "../data/all_single_product_info.json";
export default createStore({
  // plugins: [
  //   createPersistedState({
  //     storage: window.sessionStorage,
  //   }),
  // ],
  name: "Product",
  namespaced: true,
  state: {
    loading: false,
    profileModalOpen: false,
    cancelOrder: false,
    buyOneClick: false,
    openAuthEnter: false,
    openPasswordModal: false,
    openPartnersModal: false,
    goods: [],
    users: [],
    orders1: [],
    newUsers: [],
    product: [],
    card: JSON.parse(localStorage.getItem('basket')) || [],
    singleCard: [],
    liked: [],
    categories: [],
    subCategory: [],
    marketItem: [],
    shopCards: [],
    singleMarketShop: [],
    news: [],
    lang: localStorage.getItem('lang') || "ru",
    orders: [],
    searchValue: [],
    singleProductInfo: [],
    allFeedbacks: [],
    allProductInfo: [],
    // cdn: "https://ges-cdn.test-nomad.kz/",
    isAuth: false,
    unAuth: false,
    userType: localStorage.getItem('type') || '',
    userI:[],
  },

  getters: {
    getLoading: (state) => state.loading,
    STATE(state) {
      return state;
    },
    SEARCH_VALUE(state) {
      return state.searchValue;
    },
    ACCOUNT_ORDERS(state) {
      return state.orders;
    },
    getUnAuth: (state) => state.unAuth,
    getCard: (state) => state.card,
    getLiked: (state) => state.liked,
    getIsAuth: (state) => state.isAuth,
    allCategories: (state) => state.categories,
    allNews: (state) => state.news,
    getUser: (state) => state.setUser,
    getOrderList: (state) => state.orders1,
    getLang: (state) => state.lang,
    getType: (state) => state.userType
  },

  mutations: {

    SET_LOADING(state, value) {
      state.loading = value;
    },
    
    UPDATE_LANG(state, lang) {
      state.lang = lang
      localStorage.setItem("lang", lang);
    },
    SET_TYPE(state, type) {
      state.userType = type;
    },
    SET_SHOP_CARD(state, product) {
      state.singleCard = product;
    },
    SET_CARD(state, item) {
      state.card = (item);
    },
    SET_LIKED(state, product) {
      // console.log("set liked work");
      state.liked.push(product);
    },
    SET_CATEGORY(state, categories) {
      state.categories = categories;
    },
    SET_NEWS(state, news) {
      state.news = news;
    },
    SET_SUB_CATEGORY(state, subCategory) {
      state.subCategory = subCategory;
    },
    PRODUCT_LIST(state, payload) {
      state.product = payload;
    },
    SET_MARKET_ITEM(state, item) {
      state.marketItem.push(item);
    },
    SET_MARKET_ITEM_SINGLE(state, item) {
      state.singleMarketShop = item;
    },
    DELETE_LIKED_GOOD(state, id) {
      state.liked = state.liked.filter(i => parseInt(i.product_id) != parseInt(id))
      // localStorage.setItem('favourites', JSON.stringify(state.liked) )
    },
    DELETE_MARKET_ITEM(state, id) {
      let item = state.marketItem.find((it) => it.id === parseInt(id));
      let index = state.marketItem.indexOf(item);
      state.marketItem.splice(index, 1);
      // console.log(state.marketItem);
    },
    DELETE_BASKET_ITEM(state, id) {
      state.card = state.card.filter(i => i.id !== parseInt(id))
      localStorage.setItem('basket', JSON.stringify(state.card) )
    },
    DELETE_All_BASKET(state) {
      state.card = []
      localStorage.removeItem('basket')
    },
    //SET SINGLE JSON PRODUCT
    SET_SINGLE_PRODUCT_INFO(state, item) {
      state.singleProductInfo = item;
    },
    //post single feedback
    POST_FEEDBACK(state, data) {
      state.allFeedbacks.push(data);
    },
    // JSON INFO SET
    SET_ALL_FEEDBACKS(state, res) {
      state.allFeedbacks = res;
    },
    // JSON INFO SET
    SET_ALL_FAVOURITES(state, res) {
      state.liked = res;
    },
    SET_ALL_PRODUCTS_INFO(state, res) {
      state.allProductInfo = res;
    },
    SET_SEARCH_VALUE(state, value) {
      state.searchValue = value;
    },
    SET_ORDERS_LIST(state, data) {
      state.orders = data;
    },
    SET_ORDER(state, data) {
      state.orders1 = data;
    },
    SET_AUTH(state, payload) {
      state.isAuth = payload;
      // console.log(isAuth,"state")
    },
    SET_UN_AUTH(state, resp) {
      state.unAuth = resp;
    },
    setUser(state, userI) {
      state.setUser = userI;
      // console.log("state",state.setUser)
    },
  },

  actions: {
    DELETE_BASKET_ITEM({commit}, id) {
      commit("DELETE_BASKET_ITEM", id);
    },
    DELETE_CARD_ITEM({commit}, id) {
      commit("DELETE_LIKED_GOOD", id);
    },
    DELETE_MARKET_ITEM(context, id) {
      context.commit("DELETE_MARKET_ITEM", id);
    },
    async GET_MARKET_ITEM({ commit }, item) {
      commit("SET_MARKET_ITEM", item);
    },
    async GET_MARKET_ITEM_SINGLE({ commit }, item) {
      commit("SET_MARKET_ITEM_SINGLE", item);
    },
    // async GET_CATEGORY({ commit }, category) {
    //   commit("SET_CATEGORY", category);
    // },
    async getCategories({ commit }) {
      await axios
      .get(`V1/categories`)
      .then((response) => {
        commit("SET_CATEGORY", response.data.data);
      })
      .catch((e) => {
        // console.log(e);
      });
    },
    async getNews({ commit }) {
      await axios
      .get(`V1/news?lang=ru`)
      .then((response) => {
        commit("SET_NEWS", response.data.data);
      })
      .catch((e) => {
        // console.log(e);
      });
    },
    async GET_SUB_CATEGORY({ commit }, subCategory) {
      commit("SET_SUB_CATEGORY", subCategory);
    },
    ADD_PRODUCT_TO_BASKET({ commit, state }, product) {
      let item = state.card.find(i => i.id === product.id)
      if (item) {
          item.quantity++
      }
      else state.card.push({...product, quantity: product.quantity ? product.quantity : 1})
      localStorage.setItem('basket', JSON.stringify(state.card) )
    },
    ADD_LIKED_PRODUCT({ commit, state }, product) {
      let item = state.liked.find(i => i.id == product.id)
      if (item) {
          item.quantity++
      }
      else state.liked.push({...product, quantity: product.quantity ? product.quantity : 1})
      // localStorage.setItem('favourites', JSON.stringify(state.liked) )
    },

    //json info
    async GET_ALL_PRODUCTS_INFO(context) {
      const response = productAllInfo;
      context.commit("SET_ALL_PRODUCTS_INFO", response);
      return response;
    },

    async GET_ALL_FEEDBACKS(context) {
      const response = feedbacks;
      context.commit("SET_ALL_FEEDBACKS", response);
      return response;
    },
    async POST_FEEDBACK(context, data) {
      context.commit("POST_FEEDBACK", data);
    },
    // site search
    SET_SEARCH_VALUE_TO_VUEX(context, value) {
      context.commit("SET_SEARCH_VALUE", value);
    },
    async GET_PRODUCT_LIST(context) {
      const response = productData;
      context.commit("PRODUCT_LIST", response); // Рабоает
      return response;
    },
    // json info
    async GET_ORDERS_LIST(context) {
      const response = orders;
      context.commit("SET_ORDERS_LIST", response);
      return response;
    },
    getOrderItemsForPurchase(data) {
      return data;
    },
    async GET_CHECKED_PROMO_CODE(promo) {
      await axios
        .post(
          "https://0ec4a03f-e055-447b-9bcb-397e050fa22d.mock.pstmn.io/promo",
          {
            promo: promo,
          }
        )
        .then(function (response) {
          // console.log("response", response.data);
        });
    },
    checkAuth({ commit }) {
      if (localStorage.getItem("access_token")) {
        commit("SET_AUTH", true);
      }
    },
    async requestUser({ commit }) {
      if (localStorage.getItem("access_token")) {
      await axios.get("V1/me", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then(res => {
          localStorage.setItem("type", res.data.data.type);
          commit("setUser", res.data.data);
          commit("SET_AUTH", true);
          commit("SET_UN_AUTH", false);
          axios.get('/V1/orders', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }).then(res => {
            commit("SET_ORDER", res.data.data);
          }).catch(err => {
            // console.log(err);
          })
        })
        .catch(err => {
          commit("SET_UN_AUTH", true);
          commit("SET_AUTH", false);
          if (this.$router.history.current.path.includes("personal-account")) {
            this.$router.push("/");
          }
        })
        
      }
    },
    async getOrders() {
      await axios.get('/V1/orders', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }).then(res => {
        commit("SET_ORDER", res.data.data);
      }).catch(err => {
        // console.log(err);
      })
    }, 
    async logout({ commit }) {
      try {
        const response = await axios.post(
          "V1/logout",
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        commit("SET_ORDER", []);
        localStorage.removeItem("access_token");
        localStorage.removeItem("type");
        commit("setUser", []);
        
        commit("SET_AUTH", false);
      } catch (error) {
        // console.log(error);
      }
    },
  },
});
