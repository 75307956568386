<template>
  <div class="order" v-if="getUser">
    <div class="content">
      <div class="order-header__wrapper">
        <div class="order-header__back" @click="$router.push('/basket')">
          <div class="order-header__logo">
            <img src="../../assets/icons/auth/arrow_back.svg" alt="Main logo" />
          </div>
          <span>{{ this.$t("backCart") }}</span>
        </div>
        <div class="auth-header__steps">
          <div class="auth-header__icon">
            <img src="../../assets/icons/auth/registration_second.svg" alt="" />
          </div>
          <div class="auth-header__items">
            <div class="auth-header__item">1. Authorization</div>
            <div class="auth-header__item">2. Making an order</div>
            <div class="auth-header__item">3. Sending an application</div>
          </div>
        </div>
        <div></div>
      </div>

      <!-- {{ items }} -->

      <div class="order__title">Making an order</div>
      <div class="order__nocontent" v-if="!items">
        К сожалению, Making an order невозможна
      </div>
      <div v-else>
        <div class="order__wrapper">
          <div class="order__main">
            <div class="order__items">
              <div class="order__item" v-for="(item, idx) in items" :key="item.id">
                <div class="order__item-title">
                  <div class="order__item-order">Order № {{ ++idx}}</div>
                  <div class="order__item-vendor">Supplier: Someone</div>
                </div>

                <div class="desctop_order">
                  <div class="order__info">
                    <router-link :to="'/product/' + item.slug" class="order__info-img">
                      <img :src="$cdn + item.main_image" alt="IMG" />
                    </router-link>
                    <div class="order__info-about">
                      <router-link :to="'/product/' + item.slug" class="order__info-name">
                        {{ item.title }}
                      </router-link>
                      <div class="order__info-amount">
                        {{ item.quantity }} {{ $t("pc") }}
                      </div>
                      <div class="order__item-delivery">
                        <div class="order__item-delivery-info">
                          <div class="order__item-delivery-icon">
                            <img
                              src="../../assets/icons/basket/box.svg"
                              alt=""
                            />
                          </div>
                          <div class="order__item-delivery-text">
                            {{ $t("pickup") }} - London
                          </div>
                        </div>
                        <div class="order__item-delivery-info">
                          <div class="order__item-delivery-icon">
                            <img
                              src="../../assets/icons/basket/car.svg"
                              alt=""
                            />
                          </div>
                          <div class="order__item-delivery-text">
                            Tomorrow, May 21, from 1,000 £
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="order__way">
                <div class="order__subtitile">Способ доставки</div>
                <div class="order__way-choise">
                  <div
                    class="order__way-item"
                    :class="{ active_way: isWay === 'pickup' }"
                    @click="isWay = 'pickup'"
                  >
                    {{ $t("pickup") }}
                  </div>
                  <div
                    class="order__way-item"
                    :class="{ active_way: isWay === 'courier' }"
                    @click="isWay = 'courier'"
                  >
                    Delivery by courier
                  </div>
                </div>
              </div>

              <div class="order__pickup" v-show="isWay === 'pickup'">
                <div class="order__pickup-place">
                  <div class="order__pickup-place_label">
                    Select the issue point
                  </div>
                  <select class="order__pickup-place_input">
                    <option class="order__pickup-place_list">
                      г. Уральск, ул. Байтурсынова 231, офис 5б
                    </option>
                    <option class="order__pickup-place_list">
                      г. Уральск, ул. Байтурсынова 231, офис 5б
                    </option>
                  </select>
                </div>

                <div class="order__pickup-place-line"></div>

                <div class="order__data">
                  <div class="order__subtitile">Recipient's details</div>
                  <div class="order__data_items">
                    <div class="order__data_item">
                      <div class="order__data_name">
                        Name of the organization
                      </div>
                      <div class="order__data_input">
                        <input
                          type="text"
                          disabled
                          v-model="getUser.name"
                        />
                      </div>
                    </div>
                    <!-- <div class="order__data_item">
                      <div class="order__data_name">БИН</div>
                      <div class="order__data_input">
                        <input
                          type="text"
                          disabled
                          v-model="getUser.bin"
                        />
                      </div>
                    </div> -->
                    <div class="order__data_item">
                      <div class="order__data_name">E-mail</div>
                      <div class="order__data_input">
                        <input
                          type="text"
                          disabled
                          v-model="getUser.email"
                        />
                      </div>
                    </div>
                    <div class="order__data_item">
                      <div class="order__data_name">Phone number</div>
                      <div class="order__data_input">
                        <input type="text" v-model="getUser.phone_number" />
                      </div>
                    </div>
                  </div>
                  <div class="order__data_address">
                    <div class="order__data_name">Адрес</div>
                    <div class="order__data_biginput">
                      <input
                        ref="addressInput1"
                        :class="{changeInput: changeAddress == true}"
                        type="text"
                        v-model="getUser.actual_address"
                      />
                      <span @click="changeAddress = true; focusInput()" style="cursor: pointer;">Изменить адрес</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="order__courier" v-show="isWay === 'courier'">
                <div class="order__data_address">
                  <div class="order__data_name">Адрес</div>
                  <div class="order__data_biginput">
                    <input
                      ref="addressInput"
                      :class="{changeInput: changeAddress == true}"
                      type="text"
                      v-model="getUser.actual_address"
                    />
                    <span @click="changeAddress = true; focusInput()" style="cursor: pointer;">Изменить адрес</span>
                  </div>
                </div>
                <div class="order__courier-comment">
                  <div class="order__data_name">Комментарий к Orderу</div>
                  <div class="order__courier-input">
                    <textarea v-model="comment1" placeholder="Комментарий"></textarea>
                  </div>
                </div>

                <button class="order__courier__button">Отправить</button>

                <div class="order__courier-line"></div>

                <div class="order__data">
                  <div class="order__subtitile">Recipient's details</div>
                  <div class="order__data_items">
                    <div class="order__data_item">
                      <div class="order__data_name">
                        Name of the organization
                      </div>
                      <div class="order__data_input">
                        <input
                          type="text"
                          disabled
                          v-model="getUser.name"
                        />
                      </div>
                    </div>
                    <!-- <div class="order__data_item">
                      <div class="order__data_name">БИН</div>
                      <div class="order__data_input">
                        <input
                          type="text"
                          disabled
                          v-model="getUser.bin"
                        />
                      </div>
                    </div> -->
                    <div class="order__data_item">
                      <div class="order__data_name">E-mail</div>
                      <div class="order__data_input">
                        <input
                          type="text"
                          disabled
                          :placeholder="getUser.email"
                        />
                      </div>
                    </div>
                    <div class="order__data_item">
                      <div class="order__data_name">Phone number</div>
                      <div class="order__data_input">
                        <input type="text" v-model="getUser.phone_number" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="order__bill order__desctop">
              <Bill :getUser="getUser" :comment="comment1" :items="items" :isWay="isWay"></Bill>
            </div>
          </div>
        </div>
        <div class="order__bill order__mobile">
          <Bill :getUser="getUser" :comment="comment1" :items="items" :isWay="isWay"></Bill>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bill from "../../components/OrderBill.vue";
import { mapActions, mapGetters } from "vuex";
import { ref } from 'vue';
export default {
  data() {
    return {
      changeAddress: false,
      isWay: "pickup",
      comment1: "",
      isChecked: false,
      sale: 1000000,
      cost_with_sale: 122222,
      goods_count: 122,
      total_cost: 2222222,
      item_count: 12,
      items: [],
    };
  },

  async created() {
    this.setOrderCards();
    // console.log("itemss", this.items);
  },
  methods: {
    ...mapActions(["GET_CHECKED_PROMO_CODE"]),
    async setOrderCards() {
      this.items = [];
      this.items = this.STATE.card;
      this.items.map(item => {
        item.checked = true
      })
    },
    focusInput() {
      this.$refs.addressInput.focus();
      this.$refs.addressInput1.focus()
    }
  },
  computed: {
    ...mapGetters(["STATE",'getUser']),
  },
  components: {
    Bill,
  },
};
</script>

<style lang="scss" scoped>
.changeInput {
  background: #ffffff;
}
.active_way {
  background: rgba(5, 99, 14, 0.5);
  border-radius: 10px;
  color: white;
}

.order__desctop {
  display: block;

  @media (max-width: 991px) {
    display: none;
  }
}

.order__mobile {
  display: none;

  @media (max-width: 991px) {
    display: block;
  }
}
.order__courier-input {
  textarea {
    resize: none;
    outline: none;
    width: 100%;
    padding: 21px 16px;
    height: 143px !important;
  background: #FFFFFF;
  border: 1px solid #C2C2C2;
  }
}
.order__info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0 37px;

  &-about {
    width: 100%;
  }
}

.auth-header {
  height: 100px;
  background: #ffffff;
  padding: 20px 0 0 0;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    width: 80px;

    span {
      @media (max-width: 991px) {
        display: none;
      }
    }
  }

  &__steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px 0;

    @media (max-width: 991px) {
      display: none;
    }
  }

  &__icon {
    width: 577px;
  }

  &__items {
    display: flex;
    gap: 0 118px;
    align-items: center;

    font-size: 16px;
    font-weight: 600;
    color: #202020;
  }

  &__item {
    &:nth-child(3) {
      font-weight: 500;
      color: #c2c2c2;
    }
  }
}
.order__courier__button {
  cursor: pointer;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  font-size: 16px;
  color: white;
  background: #05630E;
  border-radius: 10px;
  height: 51px;
  transition: all 0.5s ease;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid #05630E;
    background: white;
    color: black;
    transform: scale(1.01);
  }
}

.order__courier-line {
  width: 100%;
  background: #E3E5E6;
  height: 1px;
  margin: 48px 0;
}

.order__pickup-place-line {
  width: 100%;
  background: #E3E5E6;
  height: 1px;
  margin: 48px 0;
}
.order__data {
  margin: 0 0 0 0 !important;
}
.order__data_items {
  margin-top: 0 !important;
}
</style>