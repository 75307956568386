<template>
  <div class="singleCategory">
    <div class="content">
      <BreadCrumbs :items="[[$t('favourites'), '/likeds']]" />
      <div class="singleCategory__title">{{ $t("favourites") }}</div>
      <div
        class="singleCategory__wrapper"
        v-if="getLiked.length != 0 || shops.length != 0"
      >
        <div class="singleCategory__menu" style="display: none">
          <div class="singleCategory__menu-title">{{ $t("category") }}</div>
          <div class="singleCategory__tool">
            <div class="singleCategory__tool-name">
              <div class="singleCategory__tool-icon">
                <img src="../assets/icons/home/arrow_left.png" alt="" />
              </div>
              <span>Электроинструменты</span>
            </div>
            <div class="singleCategory__tool-items">
              <div class="singleCategory__tool-item active_category">
                Перфораторы
              </div>
              <div class="singleCategory__tool-item">
                Шлифовальные и полировальные машины
              </div>
              <div class="singleCategory__tool-item">Пиление</div>
            </div>
            <div class="singleCategory__more">{{ $t("showAll") }}</div>
          </div>
          <div class="singleCategory__type">
            <div class="singleCategory__type-title">Тип</div>
            <div class="singleCategory__type-items">
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Шлифмашина угловая</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Дрель-шуруповерт</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Дрель</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Перфоратор</span>
              </div>
            </div>
            <div class="singleCategory__more">{{ $t("showAll") }}</div>
          </div>
          <div class="singleCategory__type">
            <div class="singleCategory__type-title">{{ $t("brand") }}</div>
            <div class="singleCategory__type-items">
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Шлифмашина угловая</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Дрель-шуруповерт</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Дрель</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Перфоратор</span>
              </div>
            </div>
            <div class="singleCategory__more">{{ $t("showAll") }}</div>
          </div>
          <div class="singleCategory__cost">
            <div class="singleCategory__type-title">{{ $t("price") }}</div>
            <div class="singleCategory__cost-slider">
              <!-- <input type="range" v-modal.number="minPrice"> -->
              <!-- <input type="range" v-modal.number="maxPrice"> -->
            </div>
            <div class="singleCategory__cost-input">
              <input type="text" v-model="min_cost" />
              <input type="text" v-model="max_cost" />
            </div>

            <div class="singleCategory__additional">
              <div class="singleCategory__additional-title">
                Напряжение аккумулятора
              </div>
              <div class="singleCategory__type-items">
                <div class="singleCategory__type-item">
                  <input type="checkbox" />
                  <span>18 В</span>
                </div>
                <div class="singleCategory__type-item">
                  <input type="checkbox" />
                  <span>18 В</span>
                </div>
                <div class="singleCategory__type-item">
                  <input type="checkbox" />
                  <span>18 В</span>
                </div>
                <div class="singleCategory__type-item">
                  <input type="checkbox" />
                  <span>18 В</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="singleCategory__main" style="width: 100%">
          <div class="liked__buttons">
            <div
              class="liked__button"
              @click="liked_btn = 1"
              :class="{ liked__button_active: liked_btn === 1 }"
            >
              {{ $t("favouritesProducts") }}
            </div>
            <div
              class="liked__button"
              @click="liked_btn = 2"
              :class="{ liked__button_active: liked_btn === 2 }"
            >
              {{ $t("favouritesShop") }}
            </div>
          </div>
          <div class="singleCategory__main-items" v-show="liked_btn === 1">
            <div
              class="singleCategory__main-item"
              v-for="item in getLiked"
              :key="item.id"
            >
              <Card :product="item" :likes="true" />
            </div>
          </div>
          <div class="singleCategory__main-items ccc" v-show="liked_btn === 2">
              <MarketCard :shop="item" v-for="(item, index) in markets" :key="index" class="marketCardStyle"> </MarketCard>
          </div>

          <div class="singleCategory__main-pagination" style="display: none">
            <div class="singleCategory__main-count">
              <span class="active_page">1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
            </div>
            <div class="singleCategory__main-icon">
              <img src="../assets/icons/components_icon/next_page.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="basket__none" v-if="getLiked.length == 0 && shops.length == 0">
        {{ $t("favouritesEmpty") }}
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/UI/BreadCrumbs.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Card from "../components/GoodsCard.vue";
import MarketCard from "../components/MarketShopCard.vue";
import markets from '@/data/markets.json'
export default {
  data() {
    return {
      markets: markets.markets,
      minPrice: 10,
      maxPrice: 1000,
      min_cost: "",
      max_cost: "",
      liked_btn: 1,
      items: [],
      shops: [],
    };
  },
  computed: {
    ...mapGetters(["getLiked"])
  },
  async created() {
    window.scrollTo(0, 0);
    await this.setLikedShops();
    this.showCompletedSections();
    
  },
  methods: {
    ...mapActions(["DELETE_CARD_ITEM"]),
    showCompletedSections() {
      if (this.getLiked.length == 0) {
        this.liked_btn = 2;
      } else {
        this.liked_btn = 1;;
      }
    },
    async setLikedShops() {
      this.shops = [];
      this.shops = this.$store.state.marketItem;
    },
  },
  components: {
    Card,
    MarketCard,
    BreadCrumbs,
  },
};
</script>

<style lang="scss" scoped>
.ccc {
  margin-top: 32px;
  @media (max-width: 768px) {
    row-gap: 25px;
  }
}
.liked {
  &__buttons {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__button {
    padding: 16px 24px;
    width: 217px;
    height: 54px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;

    @media (max-width: 991px) {
      font-size: 12px;
    }

    &_active {
      background: #05630E80;
      color: white;
      border-radius: 15px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
.singleCategory__main-item {
  width: 18.4% !important;
  @media (max-width: 991px) {
    width: 48% !important;
  }
}

.liked__button {
  &:hover {
    cursor: pointer;
  }
}
.marketCardStyle {
  width: 23%;
  @media (max-width: 768px) {
    width: 100% !important;
  }
}
</style>