<template>
  <div class="authorization block animated fadeIn">
    <div class="content">
      <div class="authorization-enter" @click.stop>
        <div class="authorization-enter__wrapper">
          <h2 class="authorization__title">{{ $t("entrance") }}</h2>

          <div class="authorization-enter__items">
            <div class="authorization-enter__item">
              <div class="authorization-enter__name">E-mail</div>
              <div class="authorization-enter__input">
                <input type="email" v-model="email" @keyup.enter="submitForm"/>
                <span v-if="v$.email.$error"
                  >Введите корректый формат email</span
                >
              </div>
            </div>
            <div class="authorization-enter__item">
              <div class="authorization-enter__name">Пароль</div>
              <div
                class="authorization-enter__input authorization-enter__password"
              >
                <div>
                  <input
                    @keyup.enter="submitForm"
                    :type="showPass ? 'password' : 'text'"
                    v-model="password.password"
                    autocomplete="nope"
                    readonly
                    onfocus="this.removeAttribute('readonly');"
                  />
                  <span class="eye" @click="showPass = !showPass">
                    <img
                      :src="
                        require(`@/assets/icons/${
                          !showPass ? 'eyeshow' : 'eyehide'
                        }.png`)
                      "
                    />
                  </span>
                </div>
                <span v-if="!v$.password.password.minLength"
                  >Пароль должен содержать минимум 8 символов</span
                >
              </div>
            </div>
          </div>
          <div class="authorization-enter__activity">
            <div class="authorization-enter__active">
              <label class="input_checkbox"
                >{{ $t("rememberMe") }}
                <input type="checkbox" id="checkbox" v-model="checked" />
                <span class="checkmark"></span>
              </label>
            </div>
            <div
              class="authorization-enter__forgot"
            >
              <p>{{ $t("forgotPassword") }}</p>
            </div>
          </div>
          <button class="button button_auth" @click="submitForm">
            {{ $t("comeIn") }}
          </button>
          <div class="authorization-enter__devider">
            <div class="authorization-enter__line"></div>
            <p>{{ $t("or") }}</p>
            <div class="authorization-enter__line"></div>
          </div>

          <div class="authorization-enter__social">
            <div class="authorization-enter__link">
              <img src="@/assets/icons/insta_black.png" alt="" />
            </div>
            <div class="authorization-enter__link">
              <img src="@/assets/icons/vk_black.png" alt="" />
            </div>
            <div class="authorization-enter__link">
              <img src="@/assets/icons/fb_black.png" alt="" />
            </div>
          </div>
          <div class="authorization-enter__registration">
            {{ $t("noAccount") }}
            <span @click="$router.push('/registration')">{{
              $t("register")
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength, email } from "@vuelidate/validators";
// import { IMaskDirective } from 'vue-imask';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: "top-right" });
import axios from "axios";
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      v$: useVuelidate(),
      email: "",
      password: {
        password: "",
      },
      checked: false,
      showPass: true,
    };
  },
  computed: {
    formValid() {
      return this.v$.$invalid;
    },
  },
  validations() {
    // const mustBeCorrectPhoneNumber = (value) => value.include('+7')
    return {
      email: { required, email },
      password: {
        password: { required, minLength: minLength(8) },
      },
      agreed: { required },
    };
  },

  methods: {
    ...mapMutations(["SET_TYPE"]),
    checkPassword(password) {
      if (password) {
        // console.log(123123);
      }
    },
    checkPhoneNumber(value) {
      return value.include("+7");
    },
    async submitForm() {
      this.v$.$validate();
      if (this.v$.$invalid) {
        // console.log('submit', this.v$);
        const article = {
          email: this.email,
          password: this.password.password,
        };
        await axios
          .post("V1/login", article)
          .then((response) => {
            if (response.status === 202) {
              localStorage.setItem("access_token", response.data.access_token);
              localStorage.setItem("type", response.data.data.type);
              this.SET_TYPE(response.data.data.type)
              toaster.success('Вы успешно авторизовались');
              this.$router.push("/acc/profile");
            }
            if (response.status === 400) {
              // console.log("Вы уже зареганны");
            } else {
              // console.log(response);
            }
          })
          .catch((e) => {
            toaster.error(e.response.data?.message);
          });
      } else {
        // console.log("Form not pass validation", this.v$);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
// .authorization {
//   overflow-y: scroll;
// }
.authorization-enter__link {
  cursor: pointer;
}
.authorization-enter {
  position: relative;
}

.closeIcon {
  cursor: pointer;
  width: auto;
  position: absolute;
  right: 16px;
  top: 16px;
  transition: all 0.7s ease;
  &:hover {
    rotate: 180deg;
    transform: scale(1.1);
  }
}



.animated {
  animation-duration: 1s; /* длительность анимации */
  animation-fill-mode: both; /* сохраняем состояние после анимации */
}
.fadeIn {
  animation-name: fadeIn; /* имя анимации */
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.authorization-enter__link {
  img {
    filter: brightness(0) saturate(100%) invert(24%) sepia(86%) saturate(893%) hue-rotate(86deg) brightness(96%) contrast(100%);
  }
}
</style>