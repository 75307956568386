<template>
    <form class="rating-area">
        <input type="radio" id="star-5" value="5" v-model="picked">
        <label for="star-5" title="Оценка «5»"></label>
        <input type="radio" id="star-4" name="rating" value="4" v-model="picked">
        <label for="star-4" title="Оценка «4»"></label>
        <input type="radio" id="star-3" name="rating" value="3" v-model="picked">
        <label for="star-3" title="Оценка «3»"></label>
        <input type="radio" id="star-2" name="rating" value="2" v-model="picked">
        <label for="star-2" title="Оценка «2»"></label>
        <input type="radio" id="star-1" name="rating" value="1" v-model="picked">
        <label for="star-1" title="Оценка «1»"></label>
    </form>
</template>

<script>
export default {
    data() {
        return {
            picked: false
        }
    },
    watch: {
        picked() {
            this.$emit('radioPickedValue', this.picked)
        }
    }
}
</script>


<style type="text/css" scoped>
.rating-area {
    overflow: hidden;
    margin: 0 auto;
}

.rating-area:not(:checked)>input {
    display: none;
}

.rating-area:not(:checked)>label {
    /* незакрашенная часть */
    float: right;
    width: 42px;
    padding: 0;
    cursor: pointer;
    font-size: 32px;
    line-height: 32px;
    color: #c4c4c4;
    text-shadow: 1px 1px #BAD900;
}

.rating-area:not(:checked)>label:before {
    content: '★';
}

.rating-area>input:checked~label {
    /* кликнутая часть */
    color: #BAD900;
    text-shadow: 1px 1px #BAD900;
}

.rating-area:not(:checked)>label:hover,
.rating-area:not(:checked)>label:hover~label {
    /* цвет при наведение  */
    color: #BAD900;
}

.rating-area>input:checked+label:hover,
.rating-area>input:checked+label:hover~label,
.rating-area>input:checked~label:hover,
.rating-area>input:checked~label:hover~label,
.rating-area>label:hover~input:checked~label {
    color: #BAD900;
    text-shadow: 1px 1px #BAD900;
}

.rate-area>label:active {
    position: relative;
}
</style>