<template>
    <div class="profileModalIn" v-if="getUser" v-click-outside="closeMod">
        <img src="@/assets/icons/close.svg" class="closeIcon" @click.stop="closeMod"/>
        <p class="profileModalIn__name" @click.stop>
            <span>{{ getUser.name }}</span> &nbsp;
            <span>{{ getUser.lname }}</span>
            <span>{{ getUser.sname }}</span>
        </p>
        <router-link :to="'/acc/profile'" class="profileModalIn__button1"  @click.stop="closeMod">Personal account</router-link>  
        <button @click.stop="LeaveAcc" class="profileModalIn__button2">Exit</button>
    </div>
    {{ $store.state.profileModal }}
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import clickOutSide from "@mahdikhashan/vue3-click-outside";
export default {
    directives: {
        clickOutSide,
    },
    data() {
        return {
            
        }
    },
    methods: {
        ...mapActions(["logout"]),
        LeaveAcc() {
            this.logout();
            this.$toaster.error('Вы вышли с аккаунта!.')
            this.$store.state.profileModalOpen = false;
            this.$router.push("/");
        },
        closeMod() {
            this.$store.state.profileModalOpen = false
        }
    },
    computed: {
        ...mapGetters(["getUser"]),
    },
}
</script>
<style lang="scss" scoped>
.closeIcon {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7472%) hue-rotate(286deg) brightness(107%) contrast(99%);
  cursor: pointer;
  width: 4%;
  position: absolute;
  right: 16px;
  top: 16px;
  transition: all 0.7s ease;
  &:hover {
    rotate: 180deg;
    transform: scale(1.1);
  }
}
.profileModalIn {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    width: 325px;
    display: flex;
    flex-direction: column;
    padding-bottom: 19px;
    &__name {
        height: 48px;
        background: #05630E;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        font-weight: 500;
    }
    &__button1 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34px;
        margin-top: 20px;
        margin-right: auto;
        margin-left: auto;
        width: 61.23%;
        background: #05630E;
        color: white;
        border-radius: 10px;
    }
    &__button2 {
        height: 34px;
        margin-top: 10px;
        margin-right: auto;
        margin-left: auto;
        width: 61.23%;
        background: #05630E;
        color: white;
        border-radius: 10px;
    }
}
</style>