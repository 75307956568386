<template>
  <div class="app">
    <div class="auth-header">
      <div class="content">
        <div class="auth-header__wrapper">
          <div class="auth-header__logo" @click="$router.push('/')">
            <img src="../../assets/img/main_logo_black.png" alt="Main logo" />
          </div>
          <div v-if="statusbBar" class="auth-header__steps">
            <div class="auth-header__icon">
              <img
                src="../../assets/icons/auth/registration_first.svg"
                alt=""
              />
            </div>
            <div class="auth-header__items">
              <div class="auth-header__item">1. Authorization</div>
              <div class="auth-header__item">2. Making an order</div>
              <div class="auth-header__item">3. Sending an application</div>
            </div>
          </div>
          <div
            class="auth-header__close"
            style="cursor: pointer"
            @click="$router.push('/')"
          >
            <img src="../../assets/icons/components_icon/close.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <slot />
    </div>

    <div class="auth-footer">
      <div class="content">
        <div class="auth-footer__wrapper">
          <div class="auth-footer__label">
            © 2022 TOO www. wwww reserved
          </div>
          <div class="auth-footer__social">
            <div class="auth-footer-item">
              <img src="../../assets/icons/insta_black.png" alt="" />
            </div>
            <div class="auth-footer-item">
              <img src="../../assets/icons/vk_black.png" alt="" />
            </div>
            <div class="auth-footer-item">
              <img src="../../assets/icons/fb_black.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      statusbBar: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.auth-header {
  height: 100px;
  background: #ffffff;
  padding: 20px 0 0 0;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    cursor: pointer;
    width: 80px;
  }

  &__steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px 0;

    @media (max-width: 991px) {
      display: none;
    }
  }

  &__icon {
    width: 577px;
  }

  &__items {
    display: flex;
    gap: 0 118px;
    align-items: center;

    font-weight: 500;
    font-size: 16px;
    color: #c2c2c2;
  }

  &__item {
    &:nth-child(1) {
      font-weight: 600;
      color: #202020;
    }
  }
}

.auth-footer {
  height: 100px;
  background: #f2f2f2;
  padding: 30px 0;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 991px) {
      width: 100%;
    }
  }

  &__label {
    font-weight: 400;
    font-size: 14px;
    font-feature-settings: "salt" on, "liga" off;
    color: #202020;

    @media (max-width: 576px) {
      font-size: 12px;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    gap: 0 16px;
  }

  &__item {
    width: 40px;
  }
}
</style>